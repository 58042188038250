import axios from "axios";

const Base_Url = "https://cms.bhandarirajan7.com.np/krishi_cms/api/v1"; // Set your base URL here

// GET request
export async function Get({ url, config = {} }) {
  try {
    const response = await axios.get(Base_Url + url, config);
    return response.data;
  } catch (error) {
    console.error("Error while making GET request:", error);
    throw error;
  }
}

// POST request
export async function Post({ url, data = {} }) {
  try {
    const response = await axios.post(Base_Url + url, data);
    return response.data;
  } catch (error) {
    console.error("Error while making POST request:", error);
    throw error;
  }
}

// PUT request
export async function Put({ url, data = {}, config = {} }) {
  try {
    const response = await axios.put(Base_Url + url, data, config);
    return response.data;
  } catch (error) {
    console.error("Error while making PUT request:", error);
    throw error;
  }
}

// PATCH request (partial update)
export async function Patch({ url, data = {}, config = {} }) {
  try {
    const response = await axios.patch(Base_Url + url, data, config);
    return response.data;
  } catch (error) {
    console.error("Error while making PATCH request:", error);
    throw error;
  }
}
