import React from "react";

const Ads = () => {
  return (
    <div>
      <div
        className="max-h-[111px] max-w-[912px] flex justify-center items-center mx-auto  my-10 text-3xl tracking-wider"
        style={{ backgroundColor: "#F5FFE8" }}
      >
        Ads
      </div>
    </div>
  );
};

export default Ads;
