import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Popover } from "antd";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";

const Share = ({ newsTitle }) => {
  const newsUrl = window.location.href;

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <div className="flex gap-[10px]">
      <Popover
        content={
          <div className="flex gap-[10px]">
            <FacebookShareButton url={newsUrl} quote={newsTitle}>
              <FontAwesomeIcon icon={faFacebook} className="w-8 h-8" />
            </FacebookShareButton>

            <TwitterShareButton url={newsUrl} title={newsTitle}>
              <FontAwesomeIcon icon={faTwitter} className="w-8 h-8" />
            </TwitterShareButton>

            <WhatsappShareButton url={newsUrl} title={newsTitle}>
              <FontAwesomeIcon icon={faWhatsapp} className="w-8 h-8" />
            </WhatsappShareButton>

            <LinkedinShareButton url={newsUrl}>
              <FontAwesomeIcon icon={faLinkedin} className="w-8 h-8" />
            </LinkedinShareButton>
          </div>
        }
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <button type="primary">
          <ion-icon name="share-social-outline" size="large"></ion-icon>
        </button>
      </Popover>
    </div>
  );
};

export default Share;
