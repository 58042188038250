import React from "react";
import myImg from "./myImg.jpg";
export default function MukhyaSamachar() {
  return (
    <div className="w-full flex flex-col gap-[15px]">
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="font-bold text-xl my-3">मुख्य समाचार</span>
        <hr
          style={{
            flex: 1,
            marginLeft: "10px",
            border: "none",
            borderTop: "1px solid #858282",
          }}
        />
      </div>
      <div className="flex w-full flex-col gap-[15px] ">
        <img
          src={myImg}
          alt="img"
          style={{
            margin: "10px 0 0px 0",
            borderRadius: "5px",
          }}
          className="w-[60%] h-[150px]"
        />
        <h2 className="font-bold text-xl line-clamp-1 w-[70%]">
          संयुक्त राष्ट्र संघक महासभामा आज विशसंयुक्त राष्ट्र संघक महासभामा आज
          विश
        </h2>
      </div>
      <hr className="h-[2px] text-black-300 my-2" />
      <div className="flex w-full flex-col gap-[15px]">
        <img
          src={myImg}
          alt="img"
          style={{
            margin: "10px 0 0px 0",
            borderRadius: "5px",
          }}
          className="w-[60%] h-[150px]"
        />
        <h2 className="font-bold text-xl line-clamp-1 w-[70%]">
          संयुक्त राष्ट्र संघक महासभामा आज विशसंयुक्त राष्ट्र संघक महासभामा आज
          विश
        </h2>
      </div>
      <hr className="h-[2px] text-black-300 my-2" />
      <div className="flex w-full flex-col gap-[15px]">
        <img
          src={myImg}
          alt="img"
          style={{
            margin: "10px 0 0px 0",
            borderRadius: "5px",
          }}
          className="w-[60%] h-[150px]"
        />
        <h2 className="font-bold text-xl line-clamp-1 w-[70%]">
          संयुक्त राष्ट्र संघक महासभामा आज विशसंयुक्त राष्ट्र संघक महासभामा आज
          विश
        </h2>
      </div>
      <hr className="h-[2px] text-black-300 my-2" />
      <div className="flex w-full flex-col gap-[15px]">
        <img
          src={myImg}
          alt="img"
          style={{
            margin: "10px 0 0px 0",
            borderRadius: "5px",
          }}
          className="w-[60%] h-[150px]"
        />
        <h2 className="font-bold text-xl line-clamp-1 w-[70%]">
          संयुक्त राष्ट्र संघक महासभामा आज विशसंयुक्त राष्ट्र संघक महासभामा आज
          विश
        </h2>
      </div>
    </div>
  );
}
