import { Checkbox } from "antd";
import { Select, Form, Input } from "antd";
import React, { useState } from "react";

const FloatingLabelInput = ({ label, name, type = "text" }) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(!!value);

  return (
    <Form.Item
      className={`floating-label-input ${focused || value ? "focused" : ""}`}
      style={{ width: "300px" }}
    >
      <label htmlFor={name}>{label}</label>
      <Input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        width={500}
      />
    </Form.Item>
  );
};

export default function CategoryAdd() {
  const [active, setActive] = useState(false);
  const activeChange = () => {
    setActive(!active);
  };
  return (
    <div className="w-full flex justify-center">
      <div style={{ width: "90%" }} className="my-5 flex flex-col gap-[20px]">
        <Select
          name="category"
          style={{
            width: "100%",
          }}
          placeholder="--Select Category--"
          // onChange={handleChange}
          options={[
            { value: "category1", label: "Category1" },
            { value: "category2", label: "Category2" },
            // { value: "bo", label: "Both" },
          ]}
        />
        <div>
          <FloatingLabelInput label="Category Name*" name="categoryname" />
          <FloatingLabelInput label="Display-Order*" name="displayorder" />
        </div>
        <Checkbox onChange={activeChange}>Category Active</Checkbox>
      </div>
    </div>
  );
}
