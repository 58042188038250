import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, Modal, Form, Input } from "antd";
import { Select } from "antd";
import { Space } from "antd";

const FloatingLabelInput = ({ label, name, type = "text" }) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(!!value);

  return (
    <Form.Item
      className={`floating-label-input ${focused || value ? "focused" : ""}`}
      style={{ width: "100%" }}
    >
      <label htmlFor={name}>{label}</label>
      <Input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};

const AuthorAdd = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [backendImages, setBackendImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch("/api/images"); // Replace with your API endpoint
        const data = await response.json();
        setBackendImages(data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchImages();
  }, []);

  const handleUpload = ({ file, onSuccess }) => {
    const image = {
      name: file.name,
      url: URL.createObjectURL(file),
    };
    setUploadedImages((prevImages) => [...prevImages, image]);
    onSuccess();
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  return (
    <div className="w-full flex justify-center">
      <div className="w-11/12 my-5 flex flex-col gap-5">
        <div className="w-full">
          <FloatingLabelInput label="Name*" name="name" />
          <Select
            options={[
              { value: "superadmin", label: "Super Admin" },
              { value: "admin", label: "Admin" },
              { value: "editor", label: "Editor" },
            ]}
            placeholder="Enter Role"
            className="w-full my-5 h-[50px]"
          />
          <FloatingLabelInput
            label="Mobile Number*"
            name="number"
            type="number"
          />
          <FloatingLabelInput label="Address*" name="address" />
          <FloatingLabelInput label="Enter Facebook URL" name="url" />
          <FloatingLabelInput label="Email*" name="email" />
          <Space direction="vertical" className="w-full mb-5">
            <Input.Password
              placeholder="Input Password"
              className="w-full h-[50px]"
            />
          </Space>
          <FloatingLabelInput label="Qualification*" name="qualification" />
        </div>

        <div className="flex flex-col gap-4">
          <Form.Item>
            <Upload maxCount={1} accept="image/*" customRequest={handleUpload}>
              <Button icon={<UploadOutlined />}>
                Choose Image from Local Device
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={toggleModal}
              className="bg-[#fcfcfc] text-black hover:bg-white"
              style={{ border: "1px solid #c2c0c0", borderRadius: "5px" }}
            >
              Upload Image From Existing Media-file
            </Button>
          </Form.Item>

          <Modal
            title="Select Existing Images"
            open={isModalOpen}
            onOk={toggleModal}
            onCancel={toggleModal}
            okButtonProps={{
              style: { color: "black", border: "1px solid #bdbbbb" }, // Add custom styles here
            }}
          >
            <div className="backend-image-selection m-5 w-full flex flex-col">
              <Input className="my-4 w-1/2" placeholder="Search Image" />
              {backendImages.length > 0 ? (
                <div className="image-gallery">
                  {backendImages.map((image) => (
                    <div
                      key={image.id}
                      className={`image-item ${selectedImage === image.id ? "selected" : ""}`}
                      onClick={() => setSelectedImage(image.id)}
                    >
                      <img src={image.url} alt={image.name} />
                    </div>
                  ))}
                </div>
              ) : (
                <p>No images available</p>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AuthorAdd;
