import React from "react";
import Logo from "../../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import NepaliDate from "nepali-date";

const TopNav = () => {
  const navigate = useNavigate();

  // Function to convert English month name to Nepali
  const getNepaliMonthName = (monthName) => {
    const nepaliMonths = [
      "बैशाख",
      "जेठ",
      "असार",
      "श्रावण",
      "भाद्र",
      "आश्विन",
      "कार्तिक",
      "मंसिर",
      "पौष",
      "माघ",
      "फाल्गुन",
      "चैत्र",
    ];
    const englishMonths = [
      "Baisakh", // Ensure these match exactly with what NepaliDate returns
      "Jestha",
      "Ashar",
      "Shrawan",
      "Bhadra",
      "Aswin",
      "Kartik",
      "Mangsir",
      "Poush",
      "Magh",
      "Falgun",
      "Chaitra",
    ];

    // Normalize monthName for comparison
    const normalizedMonthName =
      monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase();

    const index = englishMonths.indexOf(normalizedMonthName);
    return index !== -1 ? nepaliMonths[index] : "Unknown Month"; // Handle undefined case
  };

  // Function to convert English numerals to Nepali numerals
  const convertToNepaliNumerals = (number) => {
    const nepaliNumerals = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
    return String(number)
      .split("")
      .map((digit) => nepaliNumerals[parseInt(digit)])
      .join("");
  };

  // Get today's date in Nepali format
  const todayNepaliDate = new NepaliDate().format("DD MMMM YYYY dddd");

  // Extract day, month, and year
  const [day, monthName, year, dayOfWeek] = todayNepaliDate.split(" ");

  // Convert day and year to Nepali numerals
  const nepaliDay = convertToNepaliNumerals(day);
  const nepaliYear = convertToNepaliNumerals(year);

  // Get the Nepali month name
  const nepaliMonthName = getNepaliMonthName(monthName);
  // Format the final date string
  const formattedNepaliDate = `${dayOfWeek}, ${nepaliDay} ${nepaliMonthName} ${nepaliYear}`;

  return (
    <div className="h-[100px] bg-transparent py-2 w-full">
      <div className="bg-red-30 flex w-full h-full justify-between items-center">
        <div className="flex justify-center items-end flex-col">
          <img
            src={Logo}
            alt="logo"
            width={100}
            height={100}
            className="w-full h-[50px] object-contain cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          />
          <span className="font-mukta font-medium text-[#616161]">
            {formattedNepaliDate}
          </span>
        </div>
        <div className="w-full flex items-center justify-end h-[70px]">
          <div className="w-[90%] h-full bg-gray-300 flex justify-center font-mukta items-center text-xl">
            ADS
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
