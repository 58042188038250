import { API_BASE_URL } from "../config";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let onProgressChangeCallback = null;
export const setOnProgressChangeCallback = (callback) => {
  onProgressChangeCallback = callback;
};
export let percentCompletedValue = 0;

export const getuniversitys = createAsyncThunk("get/universitys", async () => {
  const config = { headers: {} };
  const response = await axios.get(
    `${API_BASE_URL}/api/university/university`,
    config
  );
  return response.data;
});

export const postuniversity = createAsyncThunk(
  "posts/university",
  async (post, thunkApi) => {
    const formData = new FormData();
    formData.append("name", post.name);
    formData.append("description", post.description);
    formData.append("countryid", post.countryid);
    formData.append("image", post.image);

    try {
      // Make the API request using axios
      const response = await axios.post(
        `${API_BASE_URL}/api/university/university`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": JSON.parse(localStorage.getItem("token")),
          },
          onUploadProgress: (progressEvent) => {
            percentCompletedValue = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (onProgressChangeCallback) {
              onProgressChangeCallback(percentCompletedValue);
            }
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateuniversity = createAsyncThunk(
  "update/university",
  async (updatedPost, thunkApi) => {
    try {
      const formData = new FormData();
      formData.append("name", updatedPost.name);
      formData.append("description", updatedPost.description);
      formData.append("countryid", updatedPost.countryid);
      formData.append("image", updatedPost.image);

      const config = {
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
        },
        onUploadProgress: (progressEvent) => {
          percentCompletedValue = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (onProgressChangeCallback) {
            onProgressChangeCallback(percentCompletedValue);
          }
        },
      };

      const response = await axios.put(
        `${API_BASE_URL}/api/university/university/${updatedPost.id}`,
        formData,
        config
      );

      return response.data;
    } catch (error) {
      // Handle errors appropriately, e.g., dispatch an error action
      throw error;
    }
  }
);

export const deleteuniversity = createAsyncThunk(
  "delete/university",
  async (postId, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    await axios.delete(
      `${API_BASE_URL}/api/university/university/${postId}`,
      config
    );
    return postId;
  }
);

const initialState = {
  universityData: [],
  loading: false,
  posts: [],
};

const universitySlice = createSlice({
  name: "universitys",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getuniversitys.fulfilled, (state, action) => {
      state.loading = false;
      state.universityData = action.payload;
    });

    builder.addCase(getuniversitys.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(postuniversity.fulfilled, (state, action) => {
      state.posts.push(action.payload);
      state.universityData.push(action.payload);
    });

    builder.addCase(updateuniversity.fulfilled, (state, action) => {
      const updatedPost = action.payload;
      let updatedData = state.universityData.filter(
        (e) => e.id !== updatedPost.id
      );
      updatedData.push(updatedPost);
      state.universityData = updatedData;
    });

    builder.addCase(deleteuniversity.fulfilled, (state, action) => {
      state.posts = state.posts.filter((post) => post.id !== action.payload);
      state.universityData = state.universityData.filter(
        (e) => e.id !== action.payload
      );
    });

    // builder.addCase(postuniversity.pending, (state, action) => {
    //   // Handle pending state for post creation if needed
    // });

    // builder.addCase(updateuniversity.pending, (state, action) => {
    //   // Handle pending state for post update if needed
    // });

    // builder.addCase(deleteuniversity.pending, (state, action) => {
    //   // Handle pending state for post deletion if needed
    // });
  },
});

export const { increment } = universitySlice.actions;
export default universitySlice.reducer;
