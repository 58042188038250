import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./FloatingLabelInput.css";

const FloatingLabelInput = ({ label, name, type = "text" }) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(!!value);

  return (
    <Form.Item
      className={`floating-label-input ${focused || value ? "focused" : ""} w-full`}
    >
      <label htmlFor={name}>{label}</label>
      <Input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Form.Item>
  );
};

const MyForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [backendImages, setBackendImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);

  useEffect(() => {
    // Fetch images from the backend
    const fetchImages = async () => {
      try {
        const response = await fetch("/api/images"); // Replace with your API endpoint
        const data = await response.json();
        setBackendImages(data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchImages();
  }, []);

  const handleUpload = ({ file, onSuccess }) => {
    const image = { name: file.name, url: URL.createObjectURL(file) };
    setUploadedImages((prevImages) => [...prevImages, image]);
    onSuccess();
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div
      style={{
        minHeight: "90%",
        backgroundColor: "#f2faf4",
        borderRadius: "10px",
        boxShadow: "0px 0px 16px #a7fab4",
      }}
      className="flex py-3 px-5 justify-center items-center w-full py-1 sm:py-[20px]"
    >
      <Form layout="inline" className="my-form flex-col gap-8 w-full">
        {/* Row 1: Name - Slogan */}
        <div className="flex flex-col gap-10 md:gap-auto md:flex-row w-full sm:w-auto">
          <FloatingLabelInput label="Name" name="name" />
          <FloatingLabelInput label="Slogan" name="slogan" />
        </div>
        {/* Row 2: Mobile Number - Email */}
        <div className="flex flex-col gap-10 md:gap-auto  w-full md:flex-row">
          <FloatingLabelInput label="Mobile Number" name="mobile" type="tel" />
          <FloatingLabelInput label="Email" name="email" type="email" />
        </div>
        {/* Row 3: File Upload - Submit */}
        <div className="flex  gap-10 md:gap-auto flex-col md:flex-row  w-full">
          <Form.Item>
            <Upload customRequest={handleUpload}>
              <Button icon={<UploadOutlined />}>
                Choose Image from Local Device
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={toggleModal}
              className="bg-[#fcfcfc] text-black hover:bg-white"
              style={{ border: "1px solid #c2c0c0", borderRadius: "5px" }}
            >
              Upload Image From Existing Media-file
            </Button>
          </Form.Item>
        </div>{" "}
        <Modal
          title="Select Existing Images"
          visible={isModalOpen}
          onOk={toggleModal}
          onCancel={toggleModal}
          okButtonProps={{
            style: { color: "black", border: "1px solid #bdbbbb" }, // Add custom styles here
          }}
        >
          <div className="backend-image-selection m-5 w-full flex flex-col">
            <Input className="my-4 w-2/4" placeholder="Search Image" />
            {backendImages.length > 0 ? (
              <div className="image-gallery">
                {backendImages.map((image) => (
                  <div
                    key={image.id}
                    className={`image-item ${selectedImage === image.id ? "selected" : ""}`}
                    onClick={() => setSelectedImage(image.id)}
                  >
                    <img src={image.url} alt={image.name} />
                  </div>
                ))}
              </div>
            ) : (
              <p>No images available</p>
            )}
          </div>
        </Modal>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MyForm;
