import React from "react";

import { useParams } from "react-router-dom";
import TrendingNews from "../../cards/newscard/sideBarComponents/TrendingNews";
import Ads from "../../cards/Ads";
import SmallCardContentRight from "../../cards/newscard/SmallCardContentRight";

export default function SearchPage() {
  const { searchValue } = useParams();
  return (
    <div className="w-full flex justify-center">
      <div className="w-[99%] md:w-[90%]">
        <div className="flex mt-7 w-full">
          <h1 className="text-2xl text-mukta  text-[#254722] w-full px-3 py-4" style={{fontWeight:'550', borderBottom:'2px solid #436e38'}}>
            Result for :- {searchValue}
          </h1>
        </div>
        <div className="my-3">
          <Ads />
        </div>

        <div className="w-full grid grid-cols-6">
          <div className=" col-span-6 h-[60vh] md:h-full overflow-scroll md:col-span-4 flex flex-wrap py-4 px-3 justify-evenly gap-[15px] sm:gap-[30px]">
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
            <div className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentRight textBlack="true" />
            </div>
          </div>
          <div className="col-span-6 md:col-span-2 ">
            <div className="sticky top-[120px]">
              <TrendingNews />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
