import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Upload } from "antd";

const homeOptions = [
  { value: "long1", label: "Landscape1" },
  { value: "long2", label: "Landscape2" },
  { value: "long3", label: "Landscape3" },
  { value: "long4", label: "Landscape4" },
  { value: "long5", label: "Landscape5" },
  { value: "long6", label: "Landscape6" },
  { value: "long7", label: "Landscape7" },
  { value: "long8", label: "Landscape8" },
  { value: "short1", label: "Small1" },
  { value: "short2", label: "Small2" },
  { value: "short3", label: "Small3" },
  { value: "short4", label: "Small4" },
  { value: "short5", label: "Small5" },
  { value: "short6", label: "Small6" },
];

const singleOptions = [
  ...homeOptions,
  { value: "short7", label: "Small7" },
  { value: "short8", label: "Small8" },
  { value: "short9", label: "Small9" },
];

export default function AdvertisementAdd() {
  const [section1, setSection1] = useState("");
  const [section2, setSection2] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [backendImages, setBackendImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  // const [title, setTitle] = useState("");

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch("/api/images"); // Replace with your API endpoint
        const data = await response.json();
        setBackendImages(data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchImages();
  }, []);

  const handleUpload = ({ file, onSuccess }) => {
    const image = {
      name: file.name,
      url: URL.createObjectURL(file),
    };
    setUploadedImages((prevImages) => [...prevImages, image]);
    onSuccess();
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(!!value);

  return (
    <div className="p-5 border border-black rounded-md w-full">
      <h2 className="text-lg font-mukta mx-3 px-1 bg-white w-fit -mt-7">
        Section selection
      </h2>
      <div className="my-3 flex flex-col gap-5">
        <Select
          name="section1"
          style={{ width: "100%" }}
          onChange={setSection1}
          placeholder="--Which page--"
          options={[
            { value: "home", label: "Home page" },
            { value: "single", label: "Single page" },
          ]}
        />
        <Select
          name="section2"
          style={{ width: "100%" }}
          onChange={setSection2}
          placeholder="--Which part--"
          options={
            section1 === ""
              ? []
              : section1 === "home"
                ? homeOptions
                : singleOptions
          }
        />
      </div>
      <div className="flex flex-col my-3 gap-4">
        <Form.Item
          className={`floating-label-input my-3 ${focused || value ? "focused" : ""}`}
          style={{ width: "100%" }}
        >
          <label htmlFor="link">Link</label>
          <Input
            id="link"
            name="Link"
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item>
          <Upload customRequest={handleUpload}>
            <Button icon={<UploadOutlined />}>
              Choose File from Local Device
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={toggleModal}
            className="bg-[#fcfcfc] text-black hover:bg-white"
            style={{ border: "1px solid #c2c0c0", borderRadius: "5px" }}
          >
            Upload Image From Existing Media-file
          </Button>
        </Form.Item>
        <Modal
          title="Select Existing Images"
          open={isModalOpen}
          onOk={toggleModal}
          onCancel={toggleModal}
          okButtonProps={{
            style: { color: "black", border: "1px solid #bdbbbb" }, // Add custom styles here
          }}
        >
          <div className="backend-image-selection m-5 w-full flex flex-col">
            <Input className="my-4 w-1/2" placeholder="Search Image" />
            {backendImages.length > 0 ? (
              <div className="image-gallery">
                {backendImages.map((image) => (
                  <div
                    key={image.id}
                    className={`image-item ${selectedImage === image.id ? "selected" : ""}`}
                    onClick={() => setSelectedImage(image.id)}
                  >
                    <img src={image.url} alt={image.name} />
                  </div>
                ))}
              </div>
            ) : (
              <p>No images available</p>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
}
