import React from "react";
import Image from "../../../page/Home/image.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../../redux/ScrollToTop/ScrollToTop";

const SmallCardContentRight = ({
  showParagraph = false,
  textBlack = false,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-full group cursor-pointer h-full overflow-hidden font-mukta">
      <div
        className="flex h-full  gap-5 "
        onClick={() => {
          navigate("/story");
          ScrollToTop();
        }}
      >
        <div className=" w-[150px] overflow-hidden h-full relative bg-black group">
          <img
            src={Image}
            alt=""
            className="w-full h-full group-hover:opacity-80 group-hover:scale-110 duration-150 object-cover"
          />
        </div>
        <div className="w-[60%] flex flex-col justify-center gap-1 items-center">
          <h2
            className={`${textBlack ? "text-black" : "text-white"} text-xl md:text-2xl line-clamp-3 !font-medium`}
            style={{ lineHeight: "1.5" }}
          >
            धानको फौजीकीरा पहिचान तथा व्यवस्थापन विधि
          </h2>
          {showParagraph && (
            <p
              className={`${textBlack ? "text-black/80" : "text-white/80"} line-clamp-2 !font-normal`}
              style={{ lineHeight: "1.5" }}
            >
              नेपाल सरकारले आज नयाँ नीति घोषणा गर्दै आर्थिक सुधार र प्रशासनिक
              सुधारको लागि विशेष योजना
            </p>
          )}
          <div>
            {/* <div className="flex justify-start items-center gap-2 !text-sm my-[10px]">
              <div
                className={`${textBlack ? "text-black/80" : "text-white/80"} font-mukta `}
              >
                {" "}
                १२ श्रावण २०८१
              </div>
              <div
                className={`${textBlack ? "bg-black/80" : "bg-white/70"} h-[15px] w-[1px] `}
              ></div>
              <div
                className={`${textBlack ? "text-black/80" : "text-white/80"} font-mukta `}
              >
                कृषि पर्यावरण
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallCardContentRight;
