import React from "react";
import BigCardContentUnderImage from "../../common/cards/newscard/BigCardContentUnderImage";

const Card2 = () => {
  return (
    <div className="w-full h-[auto] sm:h-[350px] md:h-[450px]">
      <div className="w-full h-full flex flex-col sm:flex-row gap-10">
        <div className="h-[300px] sm:h-auto">
          <BigCardContentUnderImage isRounded={true} isShadow={true} />
        </div>
        <div className="h-[300px] sm:h-auto">
          <BigCardContentUnderImage isRounded={true} isShadow={true} />
        </div>
        <div className="h-[300px] sm:h-auto">
          <BigCardContentUnderImage isRounded={true} isShadow={true} />
        </div>
      </div>
    </div>
  );
};

export default Card2;
