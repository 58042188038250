import React from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../../../redux/ScrollToTop/ScrollToTop";

export default function TajaSamacharBox() {
    const navigate=useNavigate()
  return (
    <div className="my-3 py-1 cursor-pointer" onClick={()=>{
        navigate("/story")
        ScrollToTop()
    }}>
      <p
        className="font-bold font-mukta text-xl line-clamp-3 text-[rgba(0,0,0,0.7)]"
        style={{ lineHeight: "1.5" }}
      >
        यो शीर्षकको लागि हो र दर्शकहरूलाई यस विशिष्ट समाचारको बारेमा स्पष्ट
        पार्नको लागि सम्पूर्ण शीर्षक यहाँ देखाइनेछ यो शीर्षकको लागि हो र
        दर्शकहरूलाई यस विशिष्ट समाचारको बारेमा स्पष्ट पार्नको लागि सम्पूर्ण
        शीर्षक यहाँ देखाइनेछ
      </p>
    </div>
  );
}
