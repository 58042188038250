import React, { useState } from "react";
import { Button, Table, Modal } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import InitialDelete from "./Buttons/InitialDelete";
import CategoryAdd from "./Buttons/CategoryAdd";

const columns = (showModal) => [
  {
    title: "S.N",
    dataIndex: "key",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Mobile number",
    dataIndex: "number",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: " Address",
    dataIndex: "address",
  },
  {
    title: "Message",
    dataIndex: "message",
  },

  {
    title: "Action",
    dataIndex: "action",
    render: (_, record) => (
      <div style={{ display: "flex", gap: "8px" }}>
        <Button type="danger">
          <InitialDelete />
        </Button>
      </div>
    ),
  },
];

const dataSource = Array.from({ length: 46 }).map((_, i) => ({
  key: i + 1,
  name: "This is name",
  number: "this is number",
  address: "this is address",
  active: `this is active`,
  email: "This is email",
  message: "this is message",
}));

const ContactTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Table
        columns={columns(showModal)}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default ContactTable;
