import React from "react";
import TrendingNewsBox from "./TrendingNewsBox";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../../../redux/ScrollToTop/ScrollToTop";

export default function TrendingNews() {
  const navigate = useNavigate();
  return (
    <div
      style={{ border: "1px solid #e0dfdc", borderRadius: "5px" }}
      className="flex flex-col gap-5"
    >
      <h2
        className="font-bold text-2xl  cursor-pointer"
        style={{ margin: "5px 10px" }}
        onClick={() => {
          navigate("/category/ट्रेन्डिङ समाचार");
          ScrollToTop();
        }}
      >
        ट्रेन्डिङ समाचार
      </h2>
      <div className="flex flex-col gap-6 my-1">
        <TrendingNewsBox />
        <hr className="bg-[#d1d1cf] mx-4 h-[2px]" />
        <TrendingNewsBox />
        <hr className="bg-[#d1d1cf] mx-4 h-[2px]" />
        <TrendingNewsBox />
        <hr className="bg-[#d1d1cf] mx-4 h-[2px]" />
        <TrendingNewsBox />
        <hr className="bg-[#d1d1cf] mx-4 h-[2px]" />
        <TrendingNewsBox />
      </div>
    </div>
  );
}
