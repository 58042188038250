import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./page/Home/Home";
import Navigation from "./common/navigation/Navigation";
import Footer from "./common/navigation/Footer";
import Login from "./page/Login/Login";
// import PageDashboard from "./page/Dashboard/PageDashboard";
import PageDashboard from "../Dashboard/PageDashboard";
import Story from "./page/Home/Story";
import Members from "./page/Home/Members";
import Table from "./page/Home/Table";
import IndividualAuthor from "./page/Home/IndividualAuthor";
import CategoryPage from "./common/cards/newscard/sideBarComponents/category page/CategoryPage";
import ScrollToTop from "./common/cards/newscard/sideBarComponents/ScrollToTop";
import SearchPage from "./common/navigation/Search Page/SearchPage";
const PageUser = () => {
  const location = useLocation();

  return (
    <div>
      {/* Render Navigation and Footer only outside dashboard routes */}
      {!location.pathname.toLowerCase().includes("/dashboard") && (
        <div className="sticky top-[-101px] z-50">
          <Navigation />
        </div>
      )}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard/*" element={<PageDashboard />} />
        <Route path="/dashboard/login" element={<Login />} />
        <Route path="/story" element={<Story />} />
        <Route path="/member" element={<Members />} />
        <Route path="/author" element={<IndividualAuthor />} />
        <Route path="/table" element={<Table />} />
        <Route path="/category/:categoryName" element={<CategoryPage />} />
        <Route path="/search/:searchValue" element={<SearchPage />} />
      </Routes>
      {!location.pathname.toLowerCase().includes("/dashboard") && <Footer />}
    </div>
  );
};

export default PageUser;
