// import React from "react";
// import { Link } from "react-router-dom";

// const Footer = () => {
//   return (
//     <div>
//       <footer className="bg-[#EAF2FF] dark:bg-gray-900 shadow">
//         <div className="w-full max-w-screen-xl mx-auto px-4 py-6 lg:py-8">
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 text-center sm:text-left">
//             <div>
//               <h2 className="mb-3 text-base font-poppins font-semibold text-gray-900 uppercase dark:text-white">
//                 krishiSanjal
//               </h2>
//               <ul className="text-gray-500 dark:text-gray-400 font-medium">
//                 <li className="mb-4 text-[0.8rem] font-poppins">
//                   <Link to="/about-us" className="hover:underline">
//                     About Us
//                   </Link>
//                 </li>
//                 <li className="mb-4 text-[0.8rem] font-poppins">
//                   <Link to="/study-abroad" className="hover:underline">
//                     Study Abroad
//                   </Link>
//                 </li>
//                 <li className="mb-4 text-[0.8rem] font-poppins">
//                   <Link to="/work-abroad" className="hover:underline">
//                     Work Abroad
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//             <div>
//               <h2 className="mb-3 text-base font-poppins font-semibold text-gray-900 uppercase dark:text-white">
//                 Help Center
//               </h2>
//               <ul className="text-gray-500 dark:text-gray-400 font-medium">
//                 <li className="mb-4 text-[0.8rem] font-poppins">
//                   <Link to="/" className="hover:underline">
//                     Instagram
//                   </Link>
//                 </li>
//                 <li className="mb-4 text-[0.8rem] font-poppins">
//                   <Link to="/" className="hover:underline">
//                     Twitter
//                   </Link>
//                 </li>
//                 <li className="mb-4 text-[0.8rem] font-poppins">
//                   <Link to="/" className="hover:underline">
//                     Facebook
//                   </Link>
//                 </li>
//                 <li className="mb-4 text-[0.8rem] font-poppins">
//                   <Link to="/" className="hover:underline">
//                     Contact Us
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//             <div>
//               <h2 className="mb-3 text-base font-poppins font-semibold text-gray-900 uppercase dark:text-white">
//                 Study/Work
//               </h2>
//               <ul className="text-gray-500 dark:text-gray-400 font-medium">
//                 <li className="mb-4 text-[0.8rem] font-poppins">
//                   <Link to="/universities" className="hover:underline">
//                     Universities in abroad
//                   </Link>
//                 </li>
//                 <li className="mb-4 text-[0.8rem] font-poppins">
//                   <Link to="/works" className="hover:underline">
//                     Works in abroad
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//             <div className="hidden md:block">
//               <iframe
//                 src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14124.838448411701!2d85.3006855!3d27.74168005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1721028572491!5m2!1sen!2snp"
//                 className="w-full"
//                 height="210"
//                 style={{ border: 0 }}
//                 allowFullScreen=""
//                 loading="lazy"
//                 referrerPolicy="no-referrer-when-downgrade"
//                 title="Google Maps Embed"
//               ></iframe>
//             </div>
//           </div>
//           <div className="md:hidden mt-6">
//             <div className="w-full flex justify-center items-center">
//               <iframe
//                 src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14124.838448411701!2d85.3006855!3d27.74168005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1721028572491!5m2!1sen!2snp"
//                 className="w-full"
//                 height="210"
//                 style={{ border: 0 }}
//                 allowFullScreen=""
//                 loading="lazy"
//                 referrerPolicy="no-referrer-when-downgrade"
//                 title="Google Maps Embed"
//               ></iframe>
//             </div>
//           </div>
//         </div>
//         <div className="bg-[#C7C1FF] py-4">
//           <div className="max-w-screen-xl mx-auto flex justify-center items-center">
//             <span className="text-[0.8rem] font-poppins text-black tracking-wide text-center">
//               Copyright © {new Date().getFullYear()}{" "}
//               <Link to="/"> G.A.S.</Link> All Rights Reserved.
//             </span>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default Footer;

import React from "react";
import logoImg from "./logo.png";
import Contact from "../cards/newscard/sideBarComponents/Contact";
import { Link } from "react-router-dom";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <hr
        className="my-10"
        style={{
          flex: 1,
          border: "none",
          borderTop: "2px solid #999c98",
        }}
      />
      <div style={{ width: "95%", margin: "50px auto" }}>
        <div className="grid grid-cols-4">
          <div className=" col-span-4 lg:col-span-1 md:col-span-2   flex items-center">
            <div className="p-[20px] flex flex-col justify-center gap-[10px] item-center">
              <img src={logoImg} alt="logo"></img>
              <p>कृषि संसारको कृषि संसारको</p>
              <hr className="h-[4px] bg-black-700 mt-2" />
              <Contact />
            </div>
            <div
              className="lg:block hidden"
              style={{
                height: "90%",
                width: "50px",
                borderLeft: "1px solid #7a7979",
              }}
            ></div>
          </div>
          <div className="col-span-4 lg:col-span-3 md:col-span-2  grid-rows-2 ">
            <div className="row-span-1">
              <div className="grid grid-cols-3">
                <div className=" col-span-3  lg:col-span-1  lg:flex px-5   sm:px-0 justify-between items-center">
                  <div>
                    <h2 className="text-2xl font-bold my-5">Quick links</h2>
                    <div className="flex flex-col gap-2">
                      <h2 className=" text-l">{"> Home "}</h2>
                      <h2 className=" text-l">{"> About Us"}</h2>
                      <h2 className=" text-l">{"> Terms and Condition"}</h2>
                      <h2 className=" text-l">{"> Privacy policy"}</h2>
                      <h2 className=" text-l">{"> Contact Us"}</h2>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50px",
                      height: "90%",
                      borderLeft: "1px solid #7a7979",
                    }}
                    className="hidden sm:block"
                  ></div>
                </div>
                <div className="col-span-3 lg:col-span-2 md:col-span-3 m-[20px] flex flex-col gap-[20px]">
                  <div>
                    {" "}
                    <h2 className="p-y-[20px] text-2xl font-bold">Our Teams</h2>
                  </div>
                  <div className="grid grid-cols-4 ">
                    <div className="col-span-1 flex flex-col gap-[20px] ">
                      <div>
                        <h2>प्रधान सम्पाद</h2>
                        <h2 className="text-l font-bold">राम कृष्ण खनाल</h2>
                      </div>
                      <div>
                        <h2>सम्पाद</h2>
                        <h2 className="text-l font-bold">चिरञ्जीवी पौडल</h2>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-[20px] ">
                      <div>
                        <h2>अध्यक</h2>
                        <h2 className="text-l font-bold">चिरञ्जीवी पौडल</h2>
                      </div>
                      <div>
                        <h2>सम्पाद</h2>
                        <h2 className="text-l font-bold">चिरञ्जीवी पौडल</h2>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-[20px] ">
                      <div>
                        <h2>प्रधान सम्पाद</h2>
                        <h2 className="text-l font-bold">राम कृष्ण खनाल</h2>
                      </div>
                      <div>
                        <h2>सम्पाद</h2>
                        <h2 className="text-l font-bold">चिरञ्जीवी पौडल</h2>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-[20px] ">
                      <div>
                        <h2>प्रधान सम्पाद</h2>
                        <h2 className="text-l font-bold">राम कृष्ण खनाल</h2>
                      </div>
                      <div className="flex h-full  items-end">
                        <Link to="/member" onClick={scrollToTop}>
                          <button
                            style={{
                              border: "2px solid green",
                              borderRadius: "20px",
                              padding: "3px 10px",
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                            className="hover:bg-green-100 "
                          >
                            <h2
                              className="text-l font-bold "
                              style={{ color: "green" }}
                            >
                              View All{"   "}
                            </h2>
                            {"  "}
                            <ion-icon
                              name="arrow-forward-outline"
                              style={{ color: "green" }}
                            ></ion-icon>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row-span-1 flex items-center justify-center width-100 "
              style={{ height: "50%" }}
            >
              <div
                style={{
                  width: "90%",
                  borderRadius: "10px",
                  background: " linear-gradient(to top, #006400, #ffffff)",
                  height: "auto",
                }}
                className="grid lg:grid-cols-3 md:grid-cols-4 lg:py-2 md:py-3"
              >
                <div className="lg:col-span-1 md:col-span-3 flex py-5 pb-[100px] lg:pb-0 justify-center items-center">
                  <h2 className="text-3xl font-bold font-mukta text-[#2d5e29] ">
                    {" "}
                    For Advertisement
                  </h2>
                </div>
                <div
                  className="lg:col-span-1 md:col-span-2 px-3 flex items-center"
                  style={{ height: "100%" }}
                >
                  <div
                    className="font-mukta flex flex-col gap-[20px] border-hidden lg:border-l lg:border-solid lg:border-white"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      // Inline styles are applied for default styling, overwritten by Tailwind's lg:border-* classes
                    }}
                  >
                    <div className="flex flex-col gap-[5px]">
                      <h2 className="text-white font-bold text-sm lg:text-2xl ">
                        Ram Babu
                      </h2>
                      <h2 className="text-white text-sm lg:text-xl ">
                        Mobile No. 98000000000
                      </h2>
                      <h2 className="text-white text-sm lg:text-xl flex flex-col lg:flex-row">
                        <h2>Email :- </h2>
                        <h2>rambabu@gmail.com</h2>
                      </h2>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div
                  className="lg:col-span-1 md:col-span-2 flex items-center py-9"
                  style={{ height: "100%" }}
                >
                  <div
                    className="font-mukta flex flex-col gap-[20px] "
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="flex h-full flex-col gap-[5px]">
                      <h2 className="text-white font-bold text-sm lg:text-2xl ">
                        Ram Babu
                      </h2>
                      <h2 className="text-white text-sm lg:text-xl ">
                        Mobile No. 98000000000
                      </h2>
                      <h2 className="text-white text-sm lg:text-xl flex flex-col lg:flex-row">
                        <h2>Email :- </h2>
                        <h2>rambabu@gmail.com</h2>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "70px",
          width: "100%",
          backgroundColor: "#2d5e29",
          marginTop: "50px",
        }}
        className="flex  justify-center items-center px-2 sm:px-0"
      >
        <h2 className=" text-l " style={{ color: "white" }}>
          Copyright © 2024 | all right reserved by krishisanjal.com.{" "}
        </h2>
        <hr className="border-l-2  border-gray-300 mx-2 h-[40px] sm:h-[30px]" />

        <h2 className=" text-l" style={{ color: "white" }}>
          Developed by{" "}
          <a
            href="https://tachyonwave.com/"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            Tachyonwave
          </a>
        </h2>
      </div>
    </div>
  );
};

export default Footer;
