// store.js

import { configureStore } from "@reduxjs/toolkit";
import carouselReducer from "./slices/carouselSlice";
import countriesReducer from "./slices/countriesSlice";
import universityReducer from "./slices/universitySlice";
import workReducer from "./slices/workSlice";
import reviewReducer from "./slices/reviewsSlice";
import messageReducer from "./slices/messageSlice";

import authReducer from "./slices/authSlice";
import statisticsReducer from "./slices/statisticsSlice";

export default function makeStore() {
  return configureStore({
    reducer: {
      carouselReducer,
      countriesReducer,
      universityReducer,
      workReducer,
      authReducer,
      reviewReducer,
      messageReducer,
      statisticsReducer,
    },
  });
}

export const store = makeStore();
