import React, { useState } from "react";
import { Button, Table, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import InitialDelete from "./Buttons/InitialDelete";
import CategoryAdd from "./Buttons/CategoryAdd";
import AuthorAdd from "./Buttons/AuthorAdd";
const columns = (showModal) => [
  {
    title: "S.N",
    dataIndex: "key",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Mobile number",
    dataIndex: "number",
  },
  {
    title: "Address",
    dataIndex: "address",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Image",
    dataIndex: "img",
  },

  {
    title: "Action",
    dataIndex: "action",
    render: (_, record) => (
      <div style={{ display: "flex", gap: "8px" }}>
        <Button
          type="primary"
          onClick={showModal}
          style={{ color: "green", border: "1px solid #bdbbbb" }}
        >
          <EditOutlined />
        </Button>
        <Button type="danger">
          <InitialDelete />
        </Button>
      </div>
    ),
  },
];

const dataSource = Array.from({ length: 46 }).map((_, i) => ({
  key: i + 1,
  name: "This is name",
  number: "this is number",
  address: "this is address",
  active: `this is active`,
  email: "This is email",
  img: "this is image",
}));

const AuthorsTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Table
        columns={columns(showModal)}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
      />
      <Modal
        title="Add Author"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        scroll={{ x: "max-content" }}
        className="min-w-[55vw]"
        okText="Change"
        okButtonProps={{
          style: { color: "black", border: "1px solid #bdbbbb" }, // Add custom styles here
        }}
      >
        <AuthorAdd />
      </Modal>
    </>
  );
};

export default AuthorsTable;
