import React from "react";
import ReactPlayer from "react-player";
export default function Card8() {
  return (
    <div className="flex flex-col md:flex-row gap-5 w-full h-full overflow-hidden my-10">
      <iframe
        width="400"
        height="250"
        src="https://www.youtube.com/embed/3kpKGnSc2V8?si=aUrxtk1L06jXYyHq"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <iframe
        width="400"
        height="250"
        src="https://www.youtube.com/embed/KMHn5KEfaKI?si=7VLrGZmx5y0SpfvJ"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <iframe
        width="400"
        height="250"
        src="https://www.youtube.com/embed/XTWgCa6TTSc?si=h_wdBS1FYUFfOuMu"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  );
}
