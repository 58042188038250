import React, { useEffect, useState } from "react";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import myLogo from "../assets/logo.png";
import { Popover } from "antd";
import { color } from "framer-motion";
import { Select } from "antd";
import { useNavigation } from "./NavigationContext";
import SideBar from "./SideBar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function Navigation({ open, setOpen }) {
  const { lge, setLge } = useNavigation();
  const showDrawer = () => {
    setOpen(true);
  };
  const [popOpen, setPopOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "np",
    label: "Nepali",
  });
  const navigate = useNavigate();
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setLge(selectedOption);
  };
  const hide = () => {
    setPopOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setPopOpen(newOpen);
  };
  return (
    <div
      style={{ minHeight: "15vh", backgroundColor: "#0c3d18" }}
      className=" grid grid-cols-4 w-full md:w-[90%]"
    >
      <SideBar open={open} setOpen={setOpen} />
      <div className="col-span-2 md:col-span-1 flex items-center justify-center">
        <img
          src={myLogo}
          alt="logo"
          style={{ width: "200px", height: "70%" }}
          className="cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className=" col-span-2 hidden md:flex items-center justify-center">
        <input
          className="w-full pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-green-100 border-0 rounded-md focus:placeholder-gray-500 focus:bg-blue-50 focus:border-purple-300 focus:outline-none focus:shadow-outline-purple h-10"
          type="text"
          placeholder="Search for projects"
          aria-label="Search"
        />
      </div>
      <div className="col-span-2 md:col-span-1  flex items-center justify-center gap-[10px] md:gap-[20px]">
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={[
            { value: "en", label: "English" },
            { value: "np", label: "Nepali" },
            // { value: "bo", label: "Both" },
          ]}
        />
        ;
        <Popover
          content={
            <div>
              <div className="text-xl  font-mukta flex items-center gap-[5px] hover:bg-red-100 p-[10px] cursor-pointer">
                <ion-icon name="person-circle-outline" size="large"></ion-icon>
                Admin
              </div>
              <div className="text-xl  font-mukta flex items-center gap-[5px] hover:bg-red-100 p-[10px] cursor-pointer">
                <LogoutOutlined type="primary" style={{ fontSize: "25px" }} />
                SignOut
              </div>
            </div>
          }
          trigger="click"
          open={popOpen}
          onOpenChange={handleOpenChange}
        >
          <LogoutOutlined
            type="primary"
            style={{ fontSize: "25px", color: "white" }}
          />
        </Popover>
        <MenuUnfoldOutlined
          type="primary"
          style={{ fontSize: "25px", color: "white" }}
          onClick={showDrawer}
        />
      </div>
    </div>
  );
}
