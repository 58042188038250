import React from "react";
import Hero from "./Hero";
import Breadcrumb from "../../common/navigation/Breadcrumb";
import Card1 from "./Card1";
import Ads from "../../common/cards/Ads";
import Card2 from "./Card2";
import ContentLayout from "./ContentLayout";

const Home = () => {
  return (
    <div className="w-full flex justify center">
      <div className="min-h-[400px] w-[99%] sm:w-[90%] mx-auto">
        <Hero />
        <div className="mt-20">
          <Breadcrumb showLinks={false} myWord="समाचार" />
          <Card1 />
        </div>
        <div className="mt-10">
          <Ads />
        </div>
        <div className="mt-20 ">
          <Breadcrumb showLinks={true} myWord="कृषि प्रविधि" />
          <Card2 />
        </div>
        <div className="mt-10">
          <Ads />
        </div>
        <div className="mt-10">
          <ContentLayout mukhyaShow={false} />
        </div>
      </div>
    </div>
  );
};

export default Home;
