import React from "react";
import Breadcrumb from "../../common/navigation/Breadcrumb";
import Card3 from "./Card3";
import SideContainer from "./SideContainer";
import Ads from "../../common/cards/Ads";
import Card4 from "./Card4";
import Card5 from "./Card5";
import Card6 from "./Card6";
import Card7 from "./Card7";
import Card8 from "./Card8";
import Card9 from "./Card9";

const ContentLayout = ({ mukhyaShow }) => {
  return (
    <div className="grid grid-cols-10 gap-10 my-5 ">
      <div className="lg:col-span-7 col-span-10">
        <div className="h-auto my-5">
          <Breadcrumb showLinks={true} myWord="पशुपन्छी र मातस्य" />
          <div className="my-5">
            <Card3 />
          </div>
          <div className="my-15">
            <Ads />
          </div>
        </div>
        <div className="h-auto my-5">
          <Breadcrumb showLinks={false} myWord="विचार/ब्लग" />
          <div className="my-5">
            <Card4 />
          </div>
          <Ads />
        </div>
        <div className="h-auto my-5">
          <div className="my-5">
            <Breadcrumb showLinks={false} myWord="कृषकको कथा " />
            <Card5 />
          </div>
          <Ads />
        </div>
        <div className="h-auto my-5 mb-[80px]">
          <Breadcrumb showLinks={false} myWord="फिचर" />
          <Card6 />
        </div>
        {/* <div className="hidden md:block my-5">
          <Ads />
        </div> */}
        <div className="h-auto mt-10">
          <div className="my-5">
            <Breadcrumb showLinks={false} myWord="अनुशन्धान विषेश" />
            <Card9 />
          </div>
        </div>
        <div className="h-auto mt-[70px]">
          <div className="my-5">
            <Breadcrumb showLinks={false} myWord="अन्तरवार्ता" />
            <Card7 />
          </div>
        </div>
        <div className="h-auto  my-[80px] ">
          <Breadcrumb showLinks={false} myWord="भिडियो" />
          <Card8 />
          {/* <div className="my-5">
          </div> */}
        </div>
        <Ads />
        {/* <div className="my-5">
            <Table />
          </div> */}
      </div>
      <div className=" col-span-10 lg:col-span-3   sm:col-span-10 h-full">
        <SideContainer mukhyaShow={mukhyaShow} />
        <div className="sticky top-[180px] z-15">
          <div className="h-[250px] w-[250px]  flex my-3 justify-center items-center text-3xl">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNZxbdGmV1SQiLh8Uc49kPjthFXr86Df_1qQ&s"
              alt="Ads  "
            />
          </div>
          {/* <BicharBlog /> */}
          <div className="h-[250px] w-[250px]  flex my-3 justify-center items-center text-3xl">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNZxbdGmV1SQiLh8Uc49kPjthFXr86Df_1qQ&s"
              alt="Ads  "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentLayout;
