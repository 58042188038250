import React from "react";
import Ads from "../../../Ads";
import Breadcrumb from "../../../../navigation/Breadcrumb";
import BigCardContentRight from "../../BigCardContentRight";
import SmallCardContentBottom from "../../SmallCardContentBottom";
import TrendingNews from "../TrendingNews";
import { useParams } from "react-router-dom";

export default function CategoryPage() {
  const { categoryName } = useParams();
  return (
    <div className="w-full flex justify-center">
      <div className="w-full sm:w-[90%]">
        <div className="my-3">
          <Ads />
        </div>
        <Breadcrumb showLinks={false} myWord={categoryName} addNews={false} />
        <div className="w-full my-6">
          <BigCardContentRight showParagraph={true} />
        </div>
        <div className="w-full grid grid-cols-6">
          <div className=" col-span-6 h-[60vh] sm:h-full overflow-scroll md:col-span-4 flex flex-wrap py-4 my-5 px-3 justify-evenly gap-[15px] sm:gap-[30px]">
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
            <div className="w-[95%] h-[270px] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md">
              <SmallCardContentBottom
                lineClampTitle={1}
                lineClampDes={2}
                textBlack={true}
                showParagraph={false}
                showDate={false}
              />
            </div>
          </div>
          <div className="col-span-6 md:col-span-2 ">
            <div className="sticky top-[120px]">
              <TrendingNews />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
