import React from "react";
import myImage from "./newscard/sideBarComponents/myImg.jpg";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import ScrollToTop from "../../../redux/ScrollToTop/ScrollToTop";
const AuthorBredCrumb = ({ textBlack = true }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center gap-3">
      <div className="flex justify-center items-center gap-2">
        <Avatar
          src="https://i.pravatar.cc/150?u=a04258a2462d826712d"
          className="scale-90 mb-1 w-9 h-9 cursor-pointer"
          onClick={() => {
            navigate("/author");
          }}
        />
        <div
          className={`font-mukta cursor-pointer ${textBlack ? "text-black/80" : "text-white/80"} `}
          onClick={(event) => {
            event.stopPropagation();
            navigate("/author");
            ScrollToTop();
          }}
        >
          चिरञ्जीवी पौडेल
        </div>
      </div>
      <div
        className={`font-mukta h-[20px] w-[1px] ${textBlack ? "bg-black/80" : "bg-white/80"} `}
      ></div>
      <div
        className={`font-mukta  ${textBlack ? "text-black/80" : "text-white/80"} `}
      >
        १२ श्रावण २०८१
      </div>
      <div
        className={`font-mukta h-[20px] w-[1px]  ${textBlack ? "bg-black/80" : "bg-white/80"} `}
      ></div>
      <div
        className={`font-mukta  ${textBlack ? "text-black/80" : "text-white/80"} `}
      >
        कृषि पर्यावरण
      </div>
    </div>
  );
};

export default AuthorBredCrumb;
