import React from "react";

export default function Contact({ vertical = false }) {
  return (
    <div
      className={`contact_icon ${vertical ? "flex flex-col" : "flex"} my-4 gap-[10px] `}
      style={{
        width: "100%",
      }}
    >
      <span className="contact_icon1">
        <a
          href="#"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-facebook"></ion-icon>
        </a>
      </span>
      <span className="contact_icon2">
        <a
          href="#"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-linkedin"></ion-icon>
        </a>
      </span>
      <span className="contact_icon3">
        <a
          href="#"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-instagram"></ion-icon>
        </a>
      </span>

      <span className="contact_icon5">
        <a
          href="#"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-twitter"></ion-icon>
        </a>
      </span>
    </div>
  );
}
