import React from "react";
import memberImg from "./pic.jpg";
import { FacebookOutlined } from "@ant-design/icons";
export default function Members() {
  return (
    <div className="w-full flex  justify-center">
      <div
        // style={{
        //   width: "90%",
        //   minHeight: "100vh",
        //   display: "flex",
        //   flexWrap: "wrap",
        //   gap: "30px",
        //   justifyContent: "space-evenly",
        //   backgroundColor: "#fff9f2",
        // }}
        className="w-[99%] sm:w-[90%] my-3 py-5 px-4 flex flex-wrap gap-[10px] sm:gap-[30px] justify-evenly bg-[#fff9f2]"
      >
        <h1
          className="text-5xl font-bold font-mukta w-full text-center "
          style={{ color: "#0b5915" }}
        >
          Team Members
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#d4fad7",
            alignItems: "center",
          }}
          className="py-3 w-[15 0px] my-3 h-[200px] md:w-[250px] md:h-[300px]"
        >
          <div
            style={{
              width: "90%",
              height: "80%",
              backgroundImage: `url(${memberImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="flex justify-end items-end"
          >
            <a href="#" target="_blank" className="mx-2">
              <ion-icon name="logo-facebook" size="large"></ion-icon>
            </a>
          </div>
          <div className="w-full flex flex-col gap-1 justify-center">
            <h2 className="font-bold text-center font-mukta text-2xl px-2">
              राम कृष्ण खनाल
            </h2>
            <h2 className="font-mukta text-center text-l px-2">
              प्रधान सम्पाद
            </h2>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#d4fad7",
            alignItems: "center",
          }}
          className="py-3 w-[15 0px] my-3 h-[200px] md:w-[250px] md:h-[300px]"
        >
          <div
            style={{
              width: "90%",
              height: "80%",
              backgroundImage: `url(${memberImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="flex justify-end items-end"
          >
            <a href="#" target="_blank" className="mx-2">
              <ion-icon name="logo-facebook" size="large"></ion-icon>
            </a>
          </div>
          <div className="w-full flex flex-col gap-1 justify-center">
            <h2 className="font-bold text-center font-mukta text-2xl px-2">
              राम कृष्ण खनाल
            </h2>
            <h2 className="font-mukta text-center text-l px-2">
              प्रधान सम्पाद
            </h2>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#d4fad7",
            alignItems: "center",
          }}
          className="py-3 w-[15 0px] my-3 h-[200px] md:w-[250px] md:h-[300px]"
        >
          <div
            style={{
              width: "90%",
              height: "80%",
              backgroundImage: `url(${memberImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="flex justify-end items-end"
          >
            <a href="#" target="_blank" className="mx-2">
              <ion-icon name="logo-facebook" size="large"></ion-icon>
            </a>
          </div>
          <div className="w-full flex flex-col gap-1 justify-center">
            <h2 className="font-bold text-center font-mukta text-2xl px-2">
              राम कृष्ण खनाल
            </h2>
            <h2 className="font-mukta text-center text-l px-2">
              प्रधान सम्पाद
            </h2>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#d4fad7",
            alignItems: "center",
          }}
          className="py-3 w-[15 0px] my-3 h-[200px] md:w-[250px] md:h-[300px]"
        >
          <div
            style={{
              width: "90%",
              height: "80%",
              backgroundImage: `url(${memberImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="flex justify-end items-end"
          >
            <a href="#" target="_blank" className="mx-2">
              <ion-icon name="logo-facebook" size="large"></ion-icon>
            </a>
          </div>
          <div className="w-full flex flex-col gap-1 justify-center">
            <h2 className="font-bold text-center font-mukta text-2xl px-2">
              राम कृष्ण खनाल
            </h2>
            <h2 className="font-mukta text-center text-l px-2">
              प्रधान सम्पाद
            </h2>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#d4fad7",
            alignItems: "center",
          }}
          className="py-3 w-[15 0px] my-3 h-[200px] md:w-[250px] md:h-[300px]"
        >
          <div
            style={{
              width: "90%",
              height: "80%",
              backgroundImage: `url(${memberImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="flex justify-end items-end"
          >
            <a href="#" target="_blank" className="mx-2">
              <ion-icon name="logo-facebook" size="large"></ion-icon>
            </a>
          </div>
          <div className="w-full flex flex-col gap-1 justify-center">
            <h2 className="font-bold text-center font-mukta text-2xl px-2">
              राम कृष्ण खनाल
            </h2>
            <h2 className="font-mukta text-center text-l px-2">
              प्रधान सम्पाद
            </h2>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#d4fad7",
            alignItems: "center",
          }}
          className="py-3 w-[15 0px] my-3 h-[200px] md:w-[250px] md:h-[300px]"
        >
          <div
            style={{
              width: "90%",
              height: "80%",
              backgroundImage: `url(${memberImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="flex justify-end items-end"
          >
            <a href="#" target="_blank" className="mx-2">
              <ion-icon name="logo-facebook" size="large"></ion-icon>
            </a>
          </div>
          <div className="w-full flex flex-col gap-1 justify-center">
            <h2 className="font-bold text-center font-mukta text-2xl px-2">
              राम कृष्ण खनाल
            </h2>
            <h2 className="font-mukta text-center text-l px-2">
              प्रधान सम्पाद
            </h2>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#d4fad7",
            alignItems: "center",
          }}
          className="py-3 w-[15 0px] my-3 h-[200px] md:w-[250px] md:h-[300px]"
        >
          <div
            style={{
              width: "90%",
              height: "80%",
              backgroundImage: `url(${memberImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="flex justify-end items-end"
          >
            <a href="#" target="_blank" className="mx-2">
              <ion-icon name="logo-facebook" size="large"></ion-icon>
            </a>
          </div>
          <div className="w-full flex flex-col gap-1 justify-center">
            <h2 className="font-bold text-center font-mukta text-2xl px-2">
              राम कृष्ण खनाल
            </h2>
            <h2 className="font-mukta text-center text-l px-2">
              प्रधान सम्पाद
            </h2>
          </div>
        </div>
        
      </div>
    </div>
  );
}
