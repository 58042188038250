import React from "react";
import logoImg from "./logo.png";
import DashboardForm from "./DashboardForm";
export default function Dashboard() {
  return (
    <div
      className=" grid grid-cols-5"
      style={{ minHeight: "70vh", width: "100%" }}
    >
      <div className="col-span-5 h-[auto] py-3 md:col-span-2 flex justify-center items-center">
        <div
          style={{
            width: "85%",
            backgroundColor: "#f2faf4",
            borderRadius: "10px",
            boxShadow: "0px 0px 16px #a7fab4 ",
          }}
          className="flex h-full md:h-[90%] flex-col  items-center gap-5"
        >
          <h2
            className="font-bold text-xl font-mukta my-5 "
            style={{ color: "#406b46", textAlign: "center" }}
          >
            Empowering Farmers with Knowledge, Innovation, and Growth
          </h2>
          <img src={logoImg} alt="logo" style={{ width: "300px" }} />
          <h2
            className="text-xl font-bold font-mukta"
            style={{ color: "#919e93" }}
          >
            Welcome To Krishi Sanjal
          </h2>
        </div>
      </div>
      <div className="col-span-5  md:col-span-3 flex items-center justify-center px-4">
        <DashboardForm />
      </div>
    </div>
  );
}
