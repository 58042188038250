import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../config";

// Create async thunks
// !======================== for reviews ======================
export const getSixMonthReview = createAsyncThunk(
  "users/getSixMonthReview",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/stats/reviewcount`,
      config
    );
    return response.data;
  }
);
export const getSummaryCount = createAsyncThunk(
  "users/getSummaryCount",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/stats/summarycounts`,
      config
    );
    return response.data;
  }
);

// Define initial state
const initialState = {
  lastSixMonthReview: [],
  summaryCountData: {},
};

// Create a slice
const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // !============ reviews ================
    builder.addCase(getSixMonthReview.fulfilled, (state, action) => {
      state.loading = false;
      state.lastSixMonthReview = action.payload;
    });
    builder.addCase(getSummaryCount.fulfilled, (state, action) => {
      state.loading = false;
      state.summaryCountData = action.payload;
    });
  },
});

// Export actions and reducer
export const { increment } = statisticsSlice.actions;
export default statisticsSlice.reducer;
