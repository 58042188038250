import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navigation from "./Navigation";
import SideBar from "./SideBar";
import Dashboard from "../User/page/Login/Dashboard";
import Category from "../User/page/Login/Category";
import SubCategory from "../User/page/Login/SubCategory";
import SubSubCategory from "../User/page/Login/SubSubCategory";
import Authors from "../User/page/Login/Authors";
import Contact from "../User/page/Login/Contact";
import News from "../User/page/Login/News";
import Advertisement from "../User/page/Login/Advertisement";
import Notice from "../User/page/Login/Notice";
import Photos from "../User/page/Login/Photos";
import Videos from "../User/page/Login/Videos";
import FooterDashboard from "../User/page/Login/FooterDashboard";
import Review from "../User/page/Login/Review";

const PageDashboard = () => {
  const [open, setOpen] = useState(false);
  const [myLink, setMyLink] = useState("");
  const location = useLocation();

  const isDashboardRoute = location.pathname.startsWith("/dashboard");
  const isLoginRoute = location.pathname.includes("/dashboard/login");

  useEffect(() => {
    const formattedLink = location.pathname.slice(1); // Remove the first "/"
    const capitalizedLink = formattedLink
      .split("/")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join("/");
    setMyLink(capitalizedLink);
  }, [location.pathname]); // Dependency array to update when the path changes

  return (
    <div style={{ width: "100%" }} className="flex flex-col items-center ">
      {isDashboardRoute && (
        <>
          {!isLoginRoute && <Navigation open={open} setOpen={setOpen} />}
          <div className="flex w-full md:w-[90%]">
            <div className="w-full min-h-[85vh] bg-green-100">
              <div>
                <h2 className="text-l px-3  my-5"> {myLink}</h2>{" "}
                {/* Display the formatted link */}
              </div>
              <div>
                <Routes>
                  <Route path="" element={<Dashboard />} />
                  <Route path="category" element={<Category />} />
                  <Route path="sub-category" element={<SubCategory />} />
                  <Route path="sub-sub-category" element={<SubSubCategory />} />
                  <Route path="authors" element={<Authors />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="news" element={<News />} />
                  <Route path="advertisement" element={<Advertisement />} />
                  <Route path="footer" element={<FooterDashboard />} />
                  <Route path="review" element={<Review />} />
                  {/* <Route path="notice" element={<Notice />} /> */}
                  {/* <Route path="gallery" element={<Photos />} />
                  <Route path="videos" element={<Videos />} /> */}
                  <Route path="*" element={<div>Route not found</div>} />
                </Routes>
              </div>
              <footer
                style={{ minHeight: "5vh" }}
                className="flex items-center"
              >
                <h2
                  className="text-l font-bold px-5"
                  style={{ color: "#89918b" }}
                >
                  Copyright © 2024{" "}
                  <a
                    href="https://tachyonwave.com/"
                    style={{ textDecoration: "none", color: "#89918b" }}
                    target="_blank"
                  >
                    Tachyonwave Technologies
                  </a>
                </h2>
              </footer>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PageDashboard;
