import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  DatePicker,
  Checkbox,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { CKEditor } from "ckeditor4-react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigation } from "../../../../Dashboard/NavigationContext";

export default function NewsAdd() {
  const { lge } = useNavigation();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [author, setAuthor] = useState(() => [
    {
      value: lge === "en" ? "Krishi Sanjal" : "कृषि संजाल",
      label: lge === "en" ? "Krishi Sanjal" : "कृषि संजाल",
    },
  ]);
  const [category, setCategory] = useState("");
  const [date, setDate] = useState(null);
  const [active, setActive] = useState(false);
  const [disData, setDisData] = useState("");
  const [backendImages, setBackendImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Fetch images from the backend when the component mounts
    const fetchImages = async () => {
      try {
        const response = await fetch("/api/images"); // Replace with your API endpoint
        const data = await response.json();
        setBackendImages(data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const handleUpload = ({ file, onSuccess, onError }) => {
    try {
      const image = {
        name: file.name,
        url: URL.createObjectURL(file),
        file: file.originFileObj, // Actual file object
      };

      // Add uploaded image to state
      setSelectedImages((prevImages) => [...prevImages, image]);

      onSuccess();
    } catch (error) {
      if (onError) {
        onError(error);
      }
      console.error("Error handling file upload:", error);
    }
  };

  const handleImageClick = (image) => {
    setSelectedImages((prevImages) => [...prevImages, image]);
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    // Append form fields
    formData.append("title", title);
    formData.append("subtitle", subtitle);
    formData.append("author", JSON.stringify(author));
    formData.append("category", category);
    formData.append("date", date ? date.format("YYYY-MM-DD") : "");
    formData.append("active", active ? "true" : "false");
    formData.append("content", disData);

    // Append images
    selectedImages.forEach((image, index) => {
      formData.append(`images[${index}]`, image.file);
    });

    try {
      const response = await fetch("/api/submit", {
        // Replace with your API endpoint
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Form submitted successfully:", data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f2faf4",
        borderRadius: "10px",
      }}
      className="flex justify-center items-center xl:p-[50px] w-300 w-full "
    >
      <Form layout="inline" className="my-form flex-col gap-8 w-full">
        <div className="flex flex-col gap-2 md:gap-0 md:flex-row justify-between">
          <Form.Item>
            <Input
              placeholder={lge === "en" ? "Title" : "शीर्षक"}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Input
              placeholder={lge === "en" ? "Sub Title" : "उपशीर्षक"}
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />
          </Form.Item>
        </div>

        <div className="flex flex-col md:flex-row gap-2 md:gap-0 justify-between">
          <Form.Item>
            <Select
              mode="tags"
              value={author}
              onChange={(value) => setAuthor(value)}
              style={{
                width: "100%",
                height: "45px",
              }}
              placeholder={
                lge === "en" ? "Name of the Editor" : "सम्पादकको नाम"
              }
              options={[
                { value: "author1", label: "Author1" },
                { value: "author2", label: "Author2" },
              ]}
            />
          </Form.Item>

          <Form.Item>
            <Select
              value={category}
              placeholder={lge === "en" ? "Category" : "श्रेणी"}
              onChange={(value) => setCategory(value)}
              options={[
                { value: "Category1", label: "Category1" },
                { value: "category2", label: "category2" },
              ]}
              style={{ height: "45px" }}
            />
          </Form.Item>
        </div>

        <div className="image-selection w-full">
          <div className="local-image-upload flex flex-col lg:flex-row w-full gap-7 sm:gap-auto justify-between">
            <Upload customRequest={handleUpload}>
              <Button icon={<UploadOutlined />}>
                {lge === "en"
                  ? " Upload Image From Local Device"
                  : "स्थानीय यन्त्रबाट छवि अपलोड गर्नुहोस्"}
              </Button>
            </Upload>
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="bg-[#fcfcfc] text-black hover:bg-white"
              style={{ border: "1px solid #c2c0c0", borderRadius: "5px" }}
            >
              {lge === "en"
                ? "Upload Image From Existing Media-file"
                : "अवस्थित मिडिया फाइलबाट छवि अपलोड गर्नुहोस्"}
            </Button>
          </div>
          <Modal
            title="Select Existing Images"
            open={isModalOpen}
            onOk={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
            okButtonProps={{
              style: { color: "black", border: "1px solid #bdbbbb" },
            }}
          >
            <div className="backend-image-selection m-5 w-full flex flex-col">
              <h2 className="my-4 w-2/4">
                <Input placeholder="Search Image" />
              </h2>
              {backendImages.length > 0 ? (
                <div className="image-gallery">
                  {backendImages.map((image) => (
                    <div
                      key={image.id}
                      className={`image-item ${selectedImages.find((selected) => selected.url === image.url) ? "selected" : ""}`}
                      onClick={() => handleImageClick(image)}
                    >
                      <img src={image.url} alt={image.name} />
                    </div>
                  ))}
                </div>
              ) : (
                <p>
                  {lge === "en"
                    ? "No images available"
                    : "कुनै छविहरू उपलब्ध छैनन्"}
                </p>
              )}
            </div>
          </Modal>

          <div className="my-5 flex flex-wrap gap-2">
            {selectedImages.length > 0 && (
              <div className="selected-images-preview flex flex-wrap gap-2">
                {selectedImages.map((image, index) => (
                  <div key={index} className="selected-image">
                    <img
                      src={image.url}
                      alt={image.name}
                      style={{ width: "100px", height: "auto" }}
                    />
                    <p>{image.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="my-5 flex justify-between">
            <Form.Item>
              <DatePicker value={date} onChange={(value) => setDate(value)} />
            </Form.Item>
            <Form.Item>
              <Checkbox checked={active} onChange={() => setActive(!active)}>
                {lge === "en" ? "   Publish News" : "    समाचार प्रकाशित गर्ने"}
              </Checkbox>
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <CKEditor
            data={disData}
            onChange={(event) => {
              const data = event.editor.getData();
              setDisData(data);
            }}
          />
        </Form.Item>
        <div className="flex w-full justify-end">
          <Form.Item>
            <Button
              type="primary"
              onClick={handleSubmit}
              className="text-black font-bold bg-green-200"
            >
              {lge === "en" ? "Submit" : "पेश गर्नुहोस्"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
