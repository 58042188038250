import React from "react";
import BigCardContentUnderImage from "../../common/cards/newscard/BigCardContentUnderImage";
import SmallCardContentRight from "../../common/cards/newscard/SmallCardContentRight";

const Card1 = () => {
  return (
    <div>
      <div className="h-[auto] sm:h-[450px] md:h-[550px] w-full flex flex-col sm:flex-row justify-start gap-10 my-10 bg-[#2A5918] p-9">
        <div className="w-full sm:w-1/2 h-[300px] sm:h-auto">
          <BigCardContentUnderImage />
        </div>
        <div className="w-full sm:w-1/2 flex h-[350px] sm:h-full gap-5 flex-col">
          <SmallCardContentRight />
          <SmallCardContentRight />
          <SmallCardContentRight />
        </div>
      </div>
    </div>
  );
};

export default Card1;
