import React, { useEffect, useState } from "react";
import SmallCardContentBottom from "../../common/cards/newscard/SmallCardContentBottom";

export default function Card7() {
  const [phone, setPhone] = useState(false);
  useEffect(() => {
    const myWidth = window.innerWidth;
    if (myWidth >= 640) {
      setPhone(false);
    } else {
      setPhone(true);
    }
  }, []);
  return (
    <div className="flex flex-col gap-[30px] my-10">
      <div className="flex flex-col sm:flex-row  gap-10">
        <SmallCardContentBottom
          lineClampTitle={2}
          lineClampDes={2}
          textBlack={true}
          showParagraph={false}
          showDate={false}
        />
        <SmallCardContentBottom
          lineClampTitle={2}
          lineClampDes={2}
          textBlack={true}
          showParagraph={false}
          showDate={false}
        />
        <SmallCardContentBottom
          lineClampTitle={2}
          lineClampDes={2}
          textBlack={true}
          showParagraph={false}
          showDate={false}
        />
      </div>
      {/* <div className="row-span-1 sm:row-span-2"></div> */}
      {!phone && (
        <div className="  flex gap-10 flex-col sm:flex-row">
          <SmallCardContentBottom
            lineClampTitle={2}
            lineClampDes={2}
            textBlack={true}
            showParagraph={false}
            showDate={false}
          />
          <SmallCardContentBottom
            lineClampTitle={2}
            lineClampDes={2}
            textBlack={true}
            showParagraph={false}
            showDate={false}
          />
          <SmallCardContentBottom
            lineClampTitle={2}
            lineClampDes={2}
            textBlack={true}
            showParagraph={false}
            showDate={false}
          />
        </div>
      )}
    </div>
  );
}
