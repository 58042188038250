import React from "react";
import Image from "../../../page/Home/image.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../../redux/ScrollToTop/ScrollToTop";

const BigCardContentUnderImage = ({ isRounded = false, isShadow = false }) => {
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <div
        className={`w-full h-full overflow-hidden relative ${isRounded && "rounded-md"} ${isShadow && "shadow"} flex justify-items-end items-end cursor-pointer`}
        style={{
          backgroundImage: `url(${Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        onClick={() => {
          navigate("/story");
          ScrollToTop();
        }}
      >
        <div className="w-full h-full inset-0 bg-gradient-to-t ">
          <div className="h-full w-full flex items-center gap-2 justify-end flex-col">
            <p
              className=" px-2 md:px-2  text-2xl bg-[rgba(0,0,0,0.15)]  inset-0 bg-gradient-to-t to-[#9c9c9c02] from-[#000]  md:text-3xl w-full mx-auto text-white font-mukta line-clamp-4"
              style={{ lineHeight: "1.5" }}
            >
              पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा विषादी
              व्यवस्थापन केन्द्रको ध्यानाकर्षण
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigCardContentUnderImage;
