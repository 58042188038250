import React, { useState } from "react";
import { Button, Table, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import InitialDelete from "./Buttons/InitialDelete";
import CategoryAdd from "./Buttons/CategoryAdd";
import SubCategoryAdd from "./Buttons/SubCategoryAdd";

const columns = (showModal) => [
  {
    title: "S.N",
    dataIndex: "key",
  },
  {
    title: "Category Name",
    dataIndex: "category",
  },
  {
    title: "Sub-category Name",
    dataIndex: "subcategory",
  },
  {
    title: "Active",
    dataIndex: "active",
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (_, record) => (
      <div style={{ display: "flex", gap: "8px" }}>
        <Button
          type="primary"
          onClick={showModal}
          style={{ color: "green", border: "1px solid #bdbbbb" }}
        >
          <EditOutlined />
        </Button>
        <Button type="danger">
          <InitialDelete />
        </Button>
      </div>
    ),
  },
];

const dataSource = Array.from({ length: 46 }).map((_, i) => ({
  key: i + 1,
  category: `this is category`,
  subcategory: `this is subcategory ${i}`,
  displayorder: `this is displayorder ${i}`,
  active: `this is active`,
}));

const SubCategoryTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Table
        columns={columns(showModal)}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
      />
      <Modal
        title="Create Sub  Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { color: "black", border: "1px solid #bdbbbb" }, // Add custom styles here
        }}
      >
        <SubCategoryAdd />
      </Modal>
    </>
  );
};

export default SubCategoryTable;
