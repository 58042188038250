import React from "react";
import BigCardContentRight from "../../common/cards/newscard/BigCardContentRight";
import SmallCardContentBottom from "../../common/cards/newscard/SmallCardContentBottom";

const Card3 = () => {
  return (
    <div className="flex flex-col w-full gap-7">
      <div className="w-full">
        <BigCardContentRight showParagraph={true} />
      </div>
      <div className="h-full w-full grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-8">
        <div>
          <SmallCardContentBottom
            lineClampTitle={1}
            lineClampDes={2}
            textBlack={true}
            showParagraph={false}
          />
        </div>
        <div>
          <SmallCardContentBottom
            lineClampTitle={1}
            lineClampDes={2}
            textBlack={true}
            showParagraph={false}
          />
        </div>
        <div>
          <SmallCardContentBottom
            lineClampTitle={1}
            lineClampDes={2}
            textBlack={true}
            showParagraph={false}
          />
        </div>
        <div>
          <SmallCardContentBottom
            lineClampTitle={1}
            lineClampDes={2}
            textBlack={true}
            showParagraph={false}
          />
        </div>
        <div>
          <SmallCardContentBottom
            lineClampTitle={1}
            lineClampDes={2}
            textBlack={true}
            showParagraph={false}
          />
        </div>
        <div>
          <SmallCardContentBottom
            lineClampTitle={1}
            lineClampDes={2}
            textBlack={true}
            showParagraph={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Card3;
