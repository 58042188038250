import React from "react";
import Contact from "../../common/cards/newscard/sideBarComponents/Contact";
import BicharBlog from "../../common/cards/newscard/sideBarComponents/BicharBlog";
import MukhyaSamachar from "../../common/cards/newscard/sideBarComponents/MukhyaSamachar";
import TajaSamachar from "../../common/cards/newscard/sideBarComponents/TajaSamachar";
import Ads from "../../common/cards/Ads";
import TrendingNews from "../../common/cards/newscard/sideBarComponents/TrendingNews";

const SideContainer = ({ mukhyaShow }) => {
  return (
    <div>
      <div className="h-[150px] w-[300px]  flex justify-center items-center text-3xl my-5">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNZxbdGmV1SQiLh8Uc49kPjthFXr86Df_1qQ&s"
          alt="Ads  "
        />
      </div>
      <div className="mt-10">
        {!mukhyaShow && (
          <>
            <h2 className="text-xl font-bold">Follow Us:</h2>
            <Contact />
          </>
        )}
        <div className="h-[150px] w-[300px]  flex justify-center items-center text-3xl my-5">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNZxbdGmV1SQiLh8Uc49kPjthFXr86Df_1qQ&s"
            alt="Ads  "
          />
        </div>
        <TrendingNews />
        <div className="h-[250px] w-[250px]  flex my-3 justify-center items-center text-3xl">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNZxbdGmV1SQiLh8Uc49kPjthFXr86Df_1qQ&s"
            alt="Ads  "
          />
        </div>
        {mukhyaShow && <MukhyaSamachar />}
        {!mukhyaShow && (
          <div className="flex flex-col gap-[50px]">
            <TajaSamachar />
            <BicharBlog />
          </div>
        )}
      </div>
    </div>
  );
};

export default SideContainer;
