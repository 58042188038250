import React from "react";
import "./Login.css";
// import img1 from "../../../images/logofooter.png";
import { useState } from "react";
import { TextField } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useContext } from "react";
import LoginContext from "../../../context/LoginProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { Input } from "antd";
import { message } from "antd";
import { Post } from "../../../redux/features/API";

const Login = () => {
  const { LoginFunction } = useContext(LoginContext);
  const [showPassword, setShowPassword] = useState(false);
  const [inputfield, setinputfield] = useState({ username: "", password: "" });
  const inputfieldChange = (e) => {
    setinputfield({ ...inputfield, [e.target.name]: e.target.value });
  };
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("Authorization")) {
      // Navigate("/admin/DashBoard");
    } // eslint-disable-next-line
  }, []);
  const handleLogin = (values) => {
    if (values.username === "" || values.password === "") {
      message.error("Please Enter all details");
    } else {
      Post({ url: "/user/login/", data: values })
        .then((response) => {
          navigate("/dashboard");
          console.log(response);
          localStorage.setItem('Token',response.tokens.refresh)
        })
        .catch((error) => {
          console.error("Login failed:", error);
          message.error("Please Enter Correct Username of Password");
          // Handle error, e.g., show error message to the user
        });
    }
    // navigate("/dashboard");
    // e.target.reset();
  };

  return (
    <>
      <div id="container" className="overflow-hidden">
        {" "}
      </div>
      <div id="loginform" className="overflow-hidden">
        <Form onFinish={handleLogin}>
          {/* <img src={img1} alt="#" loading="lazy" className="!w-[300px] !m-0" /> */}
          <div id="inputfields">
            <h1
              id="login"
              className="uppercase text-[#28521f] !font-bold !text-4xl !tracking-wide"
            >
              Login
            </h1>
            <div className="username h-full w-full relative">
              <Form.Item name="user_name">
                <Input />
              </Form.Item>
            </div>

            <div className="username h-full w-full relative">
              <Form.Item name="password">
                <Input.Password />
              </Form.Item>
            </div>

            <div id="log">
              <button
                className="h-full w-full flex justify-center items-center"
                type="primary"
                htmlType="submit"
              >
                Signin
              </button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
