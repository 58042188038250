import React from "react";
import Image from "../../../page/Home/image.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../../redux/ScrollToTop/ScrollToTop";

const SmallCardContentBottom = ({
  showParagraph = false,
  textBlack = false,
  lineClampTitle = 2,
  lineClampDes = 2,
  showDate = false,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="w-full group cursor-pointer h-full overflow-hidden font-mukta"
      onClick={() => {
        navigate("/story");
        ScrollToTop();
      }}
    >
      <div className="flex h-full gap-3 flex-col">
        <div className="w-full overflow-hidden h-[250px] bg-black relative group">
          <img
            src={Image}
            alt=""
            className="w-full h-[400px] sm:h-full group-hover:opacity-80 group-hover:scale-110 duration-150 object-cover"
          />
        </div>
        <div className="w-full flex flex-col justify-start gap-[8px]">
          <h2
            className={`${textBlack ? "text-black" : "text-white"} text-2xl w-full  line-clamp-${lineClampTitle} !font-medium`}
            style={{ lineHeight: "1.5" }}
          >
            नेपाल सरकारले आज नयाँ नीति घोषणा गर्दै आर्थिक सुधार र प्रशासनिक
            सुधारको लागि विशेष योजना नेपाल सरकारले आज नयाँ नीति घोषणा
          </h2>
          {showParagraph && (
            <p
              className={`${textBlack ? "text-black/80" : "text-white/80"} line-clamp-${lineClampDes} !font-normal`}
              style={{ lineHeight: "1.5" }}
            >
              नेपाल सरकारले आज नयाँ नीति घोषणा गर्दै आर्थिक सुधार र प्रशासनिक
              सुधारको लागि विशेष योजना
            </p>
          )}
          <div>
            {showDate && (
              <div className="flex justify-start items-center gap-2 !text-sm my-[5px]">
                <div
                  className={`font-mukta  ${textBlack ? "text-[#808080]" : "text-white/70"}`}
                >
                  १२ श्रावण २०८१
                </div>
                <div
                  className={`h-[15px] w-[1px] ${textBlack ? "bg-black/70" : "bg-white/70"}`}
                ></div>
                <div
                  className={`font-mukta  ${textBlack ? "text-[#808080]" : "text-white/70"}`}
                >
                  कृषि पर्यावरण
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallCardContentBottom;
