import React, { useState } from "react";
import SmallCardContentBottom from "../../common/cards/newscard/SmallCardContentBottom";
import { Button } from "antd";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export default function Card4() {
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current slide

  const totalCards = 6; // Total number of cards (in this case, 6)
  const cardsPerSlide = 2; // Number of cards visible per slide

  const handleNext = () => {
    if (currentIndex < totalCards - cardsPerSlide) {
      setCurrentIndex(currentIndex + cardsPerSlide);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - cardsPerSlide);
    }
  };

  return (
    <div className="relative w-full overflow-hidden">
      <div
        className="flex transition-transform duration-500"
        style={{
          width: `${(totalCards / cardsPerSlide) * 100}%`, // Total width based on the number of cards
          transform: `translateX(-${(currentIndex / totalCards) * 100}%)`, // Move the slider
        }}
      >
        {[...Array(totalCards)].map((_, index) => (
          <div key={index} className="w-[50%] sm:w-[50%] h-full p-[20px]">
            <SmallCardContentBottom
              lineClampTitle={1}
              lineClampDes={2}
              textBlack={true}
              showParagraph={false}
            />
          </div>
        ))}
      </div>

      {/* Pagination buttons */}
      <div className="absolute bottom-[50%] left-0 w-full flex justify-between p-4 z-10">
        <Button
          aria-label="Previous"
          onClick={handlePrevious}
          className="bg-green-600 w-[40px] h-[40px] scale-90 text-white rounded-full hover:bg-[#2d5e29] duration-150"
        >
          <FaAngleLeft size={100} />
        </Button>

        <Button
          aria-label="Next"
          onClick={handleNext}
          className="bg-green-600 w-[40px] h-[40px] scale-90 text-white rounded-full hover:bg-[#2d5e29] duration-150"
        >
          <FaAngleRight size={100} />
        </Button>
      </div>
    </div>
  );
}
