import React, { useRef } from "react";
import ReviewSwiper from "./ReviewSwiper";
import { FaAngleRight } from "react-icons/fa6";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const RateViewer = () => {
  const swiperRef = useRef(null);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto", // Smooth scrolling
    });
  };
  return (
    <div
      className="h-[60px] w-full flex justify-start items-center border-y "
      style={{ backgroundColor: "white" }}
    >
      <div className="text-nowrap w-[130px] h-[60px] flex justify-center items-center text-#255725 bg-[#e3ffe3] text-lg font-mukta">
        <Link to="/table" onClick={scrollToTop}>
          Today's Rate
        </Link>
      </div>
      <div className="w-[calc(100%_-_170px)] px-3 font-mukta">
        <ReviewSwiper ref={swiperRef} />
      </div>
      <div className="flex justify-end items-center h-full w-[40px]">
        <Button
          onClick={() => {
            if (swiperRef.current) {
              swiperRef.current.slideNext();
            }
          }}
          aria-label="Next"
          color="success"
          variant="contained"
          className="bg-green-600 scale-90 text-white rounded-full hover:bg-green-900 duration-150"
          size="small"
          sx={{ minWidth: 0, width: 35, height: 35, borderRadius: "50%" }}
        >
          <FaAngleRight size={20} />
        </Button>
      </div>
    </div>
  );
};

export default RateViewer;
