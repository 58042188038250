import React from "react";
import AuthorBredCrumb from "../../common/cards/AuthorBredCrumb";
import Ads from "../../common/cards/Ads";
import Image from "./myImage.jpg";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../redux/ScrollToTop/ScrollToTop";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className="font-mukta ">
      <div className="my-20 flex flex-col ">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/story");
          }}
        >
          <div className="my-[10px]">
            <p
              className="text-center text-3xl sm:text-5xl max-w-[70%] mx-auto py-1 line-clamp-2"
              style={{ lineHeight: "1.5" }}
            >
              मरभूमि सलहको पहिचान तथा व्यवस्थापन
            </p>
          </div>

          <div className="w-full flex flex-row justify-center">
            <p className="w-[90%] sm:w-[50%] text-center text-[20px] sm:text-[22px] text-[#6f7370] line-clamp-1">
              यो उपशीर्षकको लागि हो र दर्शकहरूलाई यस विशिष्ट समाचारको बारेमा
              स्पष्ट पार्नको लागि सम्पूर्ण उपशीर्षक यहाँ देखाइनेछ यो उपशीर्षकको
              लागि हो र दर्शकहरूलाई यस विशिष्ट समाचारको बारेमा स्पष्ट पार्नको
              लागि सम्पूर्ण उपशीर्षक यहाँ देखाइनेछ यो उपशीर्षकको लागि हो र
              दर्शकहरूलाई यस विशिष्ट समाचारको बारेमा स्पष्ट पार्नको लागि
              सम्पूर्ण उपशीर्षक यहाँ देखाइनेछ
            </p>
          </div>
        </div>
        <div className="my-[30px]">
          <AuthorBredCrumb textBlack={true} />
        </div>
        <div className="my-1"></div>
      </div>
      <div className="max-w-[80%] mx-auto">
        <Ads />
      </div>

      {/* !================== for photo section ====================  */}
      <div
        className="my-10 w-full h-[550px]  flex justify-items-end items-end cursor-pointer"
        style={{
          backgroundImage: `url(${Image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        onClick={() => {
          navigate("/story");
          ScrollToTop();
        }}
      >
        {/* <img src={Image} alt="" className="w-full h-full  object-cover" /> */}
        <div className="w-full h-full bg-[rgba(0,0,0,0.05)]  inset-0 bg-gradient-to-t to-[#9c9c9c02] from-[#000]">
          <div className="h-full w-full flex items-center pb-9 gap-2 justify-end flex-col">
            <div className="w-full">
              <p
                className="text-center text-3xl sm:text-5xl max-w-[70%] mx-auto text-[#FCFBF4] font-mukta line-clamp-2 "
                style={{ lineHeight: "1.5" }}
              >
                मौरीपालनमा सुलसुलेको व्यवस्थापन विधि
              </p>
            </div>
            <div className="w-full flex flex-row justify-center">
              <p className="w-[90%] sm:w-[50%] text-center text-[20px] sm:text-[22px] text-[#bfbdbd] line-clamp-1">
                यो उपशीर्षकको लागि हो र दर्शकहरूलाई यस विशिष्ट समाचारको बारेमा
                स्पष्ट पार्नको लागि सम्पूर्ण उपशीर्षक यहाँ देखाइनेछ यो
                उपशीर्षकको लागि हो र दर्शकहरूलाई यस विशिष्ट समाचारको बारेमा
                स्पष्ट पार्नको लागि सम्पूर्ण उपशीर्षक यहाँ देखाइनेछ यो
                उपशीर्षकको लागि हो र दर्शकहरूलाई यस विशिष्ट समाचारको बारेमा
                स्पष्ट पार्नको लागि सम्पूर्ण उपशीर्षक यहाँ देखाइनेछ
              </p>
            </div>
            <div>
              <AuthorBredCrumb textBlack={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
