import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import { MenuOutlined } from "@ant-design/icons";
import { Button, Drawer, Radio, Space } from "antd";
import { useNavigate } from "react-router-dom";
const BottomNav = () => {
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const navigate = useNavigate();
  const showDrawer = () => {
    if (window.innerWidth < 768) {
      setOpen(true);
    }
  };
  const onClose = () => {
    setOpen(false);
    scrollToTop();
  };
  const handleMouseEnter = () => {
    if (timeoutId) clearTimeout(timeoutId); // Clear any existing timeout
    setHovered(true);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };
  const handleMouseLeave = () => {
    const id = setTimeout(() => setHovered(false), 500); // Delay of 500ms
    setTimeoutId(id);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const searched = () => {
    if (
      searchValue.trim() !== "" &&
      searchValue.trim() !== "." &&
      searchValue.trim() !== ".."
    ) {
      navigate(`/search/${searchValue}`);
      scrollToTop();
    }
  };
  return (
    <div className="top-0 z-50 bg-[#2d5e29] h-[60px] w-full flex items-center px-10 justify-end lg:justify-start">
      <ul className="hidden  lg:flex overflow-visible justify-between w-full h-full items-center !font-medium !font-mukta !text-lg gap-1">
        <li>
          <Link
            to="/"
            className="flex items-center justify-center gap-1 text-white/90 text-xl hover:text-white duration-150 group"
            onClick={scrollToTop}
          >
            गृहपृष्ठ
          </Link>
        </li>
        <li>
          <Link
            to={"/category/समाचार"}
            className="flex items-center justify-center gap-1 text-white/90 text-xl hover:text-white duration-150 group"
            onClick={scrollToTop}
          >
            समाचार
          </Link>
        </li>
        <li className="relative group">
          <Link
            to={"/category/कृषि प्रविधि"}
            className="flex items-center justify-center gap-1 text-white/90 text-xl hover:text-white duration-150 group"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span className="flex items-center gap-1">
              कृषि प्रविधि
              <FaAngleDown className="group-hover:rotate-[180deg] duration-150" />
            </span>
          </Link>
          {hovered && (
            <div
              className="absolute top-[40px] left-0 z-20 min-w-[120px] bg-green-100 opacity-100"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="flex flex-col">
                <li>
                  <Link
                    to={"/category/खाद्यान्न"}
                    className="text-black/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                    onClick={scrollToTop}
                  >
                    खाद्यान्न
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/category/तरकारी"}
                    className="text-black/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                    onClick={scrollToTop}
                  >
                    तरकारी
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/category/फलफूल"}
                    className="text-black/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                    onClick={scrollToTop}
                  >
                    फलफूल
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/category/पुष्पजन्य"}
                    className="text-black/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                    onClick={scrollToTop}
                  >
                    पुष्पजन्य
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/category/अन्य"}
                    className="text-black/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                    onClick={scrollToTop}
                  >
                    अन्य
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {/* <div className="absolute hidden group-hover:block top-[40px] left-0 z-20 min-w-[120px] bg-blue-400 opacity-0 group-hover:opacity-100">
            <ul className="flex flex-col">
              <li>
                <Link
                  to={"/category/खाद्यान्न"}
                  className="text-white/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                >
                  खाद्यान्न
                </Link>
              </li>
              <li>
                <Link
                  to={"/category/तरकारी"}
                  className="text-white/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                >
                  तरकारी
                </Link>
              </li>
              <li>
                <Link
                  to={"/category/फलफूल"}
                  className="text-white/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                >
                  फलफूल
                </Link>
              </li>
              <li>
                <Link
                  to={"/category/पुष्पजन्य"}
                  className="text-white/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                >
                  पुष्पजन्य
                </Link>
              </li>
              <li>
                <Link
                  to={"/category/अन्य"}
                  className="text-white/90 text-xl hover:text-white px-2 py-1 hover:bg-green-600 block"
                >
                  अन्य
                </Link>
              </li>
            </ul>
          </div> */}
        </li>
        <li>
          <Link
            to={"/category/पशुपन्छी र मत्स्य"}
            className="flex items-center justify-center gap-1 text-white/90 text-xl hover:text-white duration-150 group"
            onClick={scrollToTop}
          >
            पशुपन्छी र मत्स्य
          </Link>
        </li>
        <li>
          <Link
            to={"/category/विचार ब्लग"}
            className="flex items-center justify-center gap-1 text-white/90 text-xl hover:text-white duration-150 group"
            onClick={scrollToTop}
          >
            विचार/ब्लग
          </Link>
        </li>
        <li>
          <Link
            to={"/category/बजार"}
            className="flex items-center justify-center gap-1 text-white/90 text-xl hover:text-white duration-150 group"
            onClick={scrollToTop}
          >
            बजार
          </Link>
        </li>
        <li>
          <Link
            to={"/category/अनसुन्धान विषेश"}
            className="flex items-center justify-center gap-1 text-white/90 text-xl hover:text-white duration-150 group"
            onClick={scrollToTop}
          >
            अनसुन्धान विषेश
          </Link>
        </li>
        <li>
          <Link
            to={"/category/विविध"}
            className="flex items-center justify-center gap-1 text-white/90 text-xl hover:text-white duration-150 group"
            onClick={scrollToTop}
          >
            विविध
          </Link>
        </li>
        <li>
          <div className="relative m-2 rounded-full w-[250px] overflow-hidden flex justify-center items-center h-full bg-white">
            <input
              type="text"
              name="search"
              id="search"
              value={searchValue}
              onChange={handleChange}
              placeholder="Search..."
              className="w-full bg-transparent shadow px-4 pr-10 rounded-full border border-gray-400 py-1.5 font-light !text-sm"
            />
            <IoSearch
              className="absolute right-2.5 cursor-pointer"
              onClick={searched}
            />
          </div>
        </li>
      </ul>
      <Drawer
        title="Access Menu"
        // placement={() => ""}
        // closable={false}
        onClose={onClose}
        open={open}
      >
        <ul className="flex-col flex md:hidden justify-between w-full h-full items-center !font-medium !font-mukta !text-lg gap-1 bg-white">
          <li>
            <Link
              to="/"
              className="flex items-center justify-center gap-1 text-lg text-black hover:text-gray-600 duration-150 group"
              onClick={onClose}
            >
              गृहपृष्ठ
            </Link>
          </li>
          <li>
            <Link
              to="/category/समाचार"
              className="flex items-center justify-center gap-1 text-lg text-black hover:text-gray-600 duration-150 group"
              onClick={onClose}
            >
              समाचार
            </Link>
          </li>
          <li className="relative group">
            <Link
              to="/category/कृषि प्रविधि"
              className="flex items-center justify-center gap-1 text-lg text-black duration-150 group"
            >
              <span className="flex items-center gap-1 hover:text-gray-600">
                कृषि प्रविधि
                <FaAngleDown className="group-hover:rotate-180 duration-150" />
              </span>
            </Link>
            {/* Dropdown content */}
            <div className="absolute hidden group-hover:block top-[40px] left-0 z-20 min-w-[120px] bg-white border border-gray-300 shadow-lg opacity-0 group-hover:opacity-100 transition-all duration-150 ease-in-out">
              <ul className="flex flex-col">
                <li>
                  <Link
                    to="/category/खाद्यान्न"
                    className="text-black text-lg px-2 py-1 hover:bg-gray-200 block"
                    onClick={onClose}
                  >
                    खाद्यान्न
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/तरकारी"
                    className="text-black text-lg px-2 py-1 hover:bg-gray-200 block"
                    onClick={onClose}
                  >
                    तरकारी
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/फलफूल"
                    className="text-black text-lg px-2 py-1 hover:bg-gray-200 block"
                    onClick={onClose}
                  >
                    फलफूल
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/पुष्पजन्य"
                    className="text-black text-lg px-2 py-1 hover:bg-gray-200 block"
                    onClick={onClose}
                  >
                    पुष्पजन्य
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/अन्य"
                    className="text-black text-lg px-2 py-1 hover:bg-gray-200 block"
                    onClick={onClose}
                  >
                    अन्य
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link
              to="/category/पशुपन्छी र मत्स्य"
              className="flex items-center text-black justify-center gap-1 text-lg hover:text-gray-600 duration-150 group"
              onClick={onClose}
            >
              पशुपन्छी र मत्स्य
            </Link>
          </li>
          <li>
            <Link
              to="/category/विचार ब्लग"
              className="flex items-center justify-center gap-1 text-lg text-black hover:text-gray-600 duration-150 group"
              onClick={onClose}
            >
              विचार/ब्लग
            </Link>
          </li>
          <li>
            <Link
              to="/category/बजार"
              className="flex items-center justify-center gap-1 text-lg text-black hover:text-gray-600 duration-150 group"
              onClick={onClose}
            >
              बजार
            </Link>
          </li>
          <li>
            <Link
              to="/category/अनसुन्धान विषेश"
              className="flex items-center justify-center gap-1 text-lg text-black hover:text-gray-600 duration-150 group"
              onClick={onClose}
            >
              अनसुन्धान विषेश
            </Link>
          </li>
          <li>
            <Link
              to="/category/विविध"
              className="flex items-center justify-center gap-1 text-lg text-black hover:text-gray-600 duration-150 group"
              onClick={onClose}
            >
              विविध
            </Link>
          </li>
        </ul>
      </Drawer>
      <div className="flex items-center justify-center gap-7">
        <li
          className=" w-[38px] h-[38px] bg-[#5099336c] text-white/80 hover:text-white list-none  hover:border-white/500 flex justify-center items-center !text-sm !font-semibold rounded-full cursor-pointer hover:bg-[#295817] duration-150"
          style={{ border: "2px solid white" }}
        >
          Eng
        </li>
        <li className="list-none">
          <div className="h-full flex items-center justify-center gap-[0.31rem] flex-col cursor-pointer group duration-150">
            {/* <div className="h-[3px] w-[25px] rounded-full bg-white/90 text-lg group-hover:bg-white"></div>
              <div className="h-[3px] w-[25px] rounded-full bg-white/90 text-lg group-hover:bg-white"></div>
              <div className="h-[3px] w-[25px] rounded-full bg-white/90 text-lg group-hover:bg-white"></div> */}
            <MenuOutlined
              className="text-white text-xl"
              type="primary"
              onClick={showDrawer}
            />
          </div>
        </li>
      </div>
    </div>
  );
};

export default BottomNav;
