import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

const vegetableRates = [
  { name: "Big Tomato", rate: "Rs. 150 per KG" },
  { name: "Potato", rate: "Rs. 50 per KG" },
  { name: "Carrot", rate: "Rs. 80 per KG" },
  { name: "Spinach", rate: "Rs. 60 per KG" },
  { name: "Cabbage", rate: "Rs. 40 per KG" },
  { name: "Broccoli", rate: "Rs. 120 per KG" },
  { name: "Onion", rate: "Rs. 70 per KG" },
  { name: "Garlic", rate: "Rs. 200 per KG" },
  { name: "Cauliflower", rate: "Rs. 90 per KG" },
  { name: "Lettuce", rate: "Rs. 50 per KG" },
  { name: "Capsicum", rate: "Rs. 150 per KG" },
];

const ReviewSwiper = forwardRef((props, ref) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);
  const swiperRef = useRef(null);

  useImperativeHandle(ref, () => ({
    slideNext: () => swiperRef.current.swiper.slideNext(),
    slidePrev: () => swiperRef.current.swiper.slidePrev(),
  }));

  return (
    <Swiper
      ref={swiperRef}
      slidesPerView={isSmallScreen?2:5}
      spaceBetween={20}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 7,
          spaceBetween: 30,
        },
        1536: {
          slidesPerView: 8,
          spaceBetween: 30,
        },
      }}
      className="mySwiper "
      modules={[Autoplay]}
      style={{ backgroundColor: "white" }}
    >
      {vegetableRates.map((veg, index) => (
        <SwiperSlide key={index} className="flex justify-center items-center">
          <div className="flex flex-col justify-center items-start ">
            <span className="text-base text-black-800 font-semibold line-clamp-1">
              {veg.name}
            </span>
            <span className="text-sm text-green-600 line-clamp-1">
              {veg.rate}
            </span>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
});

export default ReviewSwiper;
