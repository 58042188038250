import React from "react";
import TajaSamacharBox from "./TajaSamacharBox";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../../../redux/ScrollToTop/ScrollToTop";

export default function TajaSamachar() {
  const navigate=useNavigate();
  return (
    <div
      style={{
        border: "2px solid #aba7a7",
        padding: "15px",
        borderRadius: "5px",
      }}
    >
      <h2 className="text-2xl font-bold cursor-pointer" onClick={()=>{
        navigate("/category/ताजा समाचार")
        ScrollToTop()
      }}>ताजा समाचार:</h2>
      <TajaSamacharBox />
      <hr className="bg-[#d1d1cf] mx-2 h-[2px] " />
      <TajaSamacharBox />
      <hr className="bg-[#d1d1cf] mx-2 h-[2px] " />
      <TajaSamacharBox />
      <hr className="bg-[#d1d1cf] mx-2 h-[2px] " />
      <TajaSamacharBox />
      {/* <hr className="bg-[#d1d1cf] mx-2 h-[2px] " /> */}
    </div>
  );
}
