import React from "react";
import Image from "../../../page/Home/image.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../../redux/ScrollToTop/ScrollToTop";

const BigCardContentRight = ({ showParagraph = false }) => {
  const navigate = useNavigate();
  return (
    <div
      className=" h-[auto] sm:h-[450px]"
      style={{ background: " linear-gradient(to top, #006400, #ffffff)" }}
    >
      <div
        className="w-full group cursor-pointer h-full overflow-hidden font-mukta"
        onClick={() => {
          navigate("/story");
          ScrollToTop();
        }}
      >
        <div className="flex flex-col sm:flex-row h-full gap-5">
          <div className="w-full sm:w-3/5 overflow-hidden h-[400px] sm:h-full relative bg-black group">
            <img
              src={Image}
              alt=""
              className="w-full h-full group-hover:opacity-80 group-hover:scale-110 duration-150 object-cover"
            />
          </div>
          <div className="w-full sm:w-2/5 flex flex-col justify-center gap-10 p-10">
            <h2
              className="text-green-900 text-3xl sm:text-3xl line-clamp-3 !font-medium leading-6"
              style={{ lineHeight: "1.5" }}
            >
              नेपाल सरकारले आज नयाँ नीति घोषणा गर्दै आर्थिक सुधार र प्रशासनिक
              सुधारको लागि विशेष योजना
            </h2>
            {showParagraph && (
              <p
                className="text-[#f5f5dc] text-base text-xl line-clamp-4 !font-normal "
                style={{ lineHeight: "1.5" }}
              >
                नेपाल सरकारले आज नयाँ नीति घोषणा गर्दै आर्थिक सुधार र प्रशासनिक
                सुधारको लागि विशेष योजना नेपाल सरकारले आज नयाँ नीति घोषणा गर्दै
                आर्थिक सुधार र प्रशासनिक सुधारको लागि विशेष योजना नेपाल सरकारले
                आज नयाँ नीति घोषणा गर्दै आर्थिक सुधार र प्रशासनिक सुधारको लागि
                विशेष योजना
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigCardContentRight;
