import { API_BASE_URL } from "../config";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let onProgressChangeCallback = null;
export const setOnProgressChangeCallback = (callback) => {
  onProgressChangeCallback = callback;
};
export let percentCompletedValue = 0;

export const getreviews = createAsyncThunk("get/reviews", async () => {
  const config = { headers: {} };
  const response = await axios.get(`${API_BASE_URL}/api/review/review`, config);
  return response.data;
});

export const postreview = createAsyncThunk(
  "posts/review",
  async (post, thunkApi) => {
    try {
      // Make the API request using axios
      const response = await axios.post(
        `${API_BASE_URL}/api/review/review`,
        post,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": JSON.parse(localStorage.getItem("token")),
          },
          onUploadProgress: (progressEvent) => {
            percentCompletedValue = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (onProgressChangeCallback) {
              onProgressChangeCallback(percentCompletedValue);
            }
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deletereview = createAsyncThunk(
  "delete/review",
  async (postId, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    await axios.delete(`${API_BASE_URL}/api/review/review/${postId}`, config);
    return postId;
  }
);

const initialState = {
  reviewData: [],
  loading: false,
  posts: [],
};

const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getreviews.fulfilled, (state, action) => {
      state.loading = false;
      state.reviewData = action.payload;
    });

    builder.addCase(getreviews.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(postreview.fulfilled, (state, action) => {
      state.posts.push(action.payload);
      state.reviewData.push(action.payload);
    });

    builder.addCase(deletereview.fulfilled, (state, action) => {
      state.posts = state.posts.filter((post) => post.id !== action.payload);
      state.reviewData = state.reviewData.filter(
        (e) => e.id !== action.payload
      );
    });
  },
});

export const { increment } = reviewSlice.actions;
export default reviewSlice.reducer;
