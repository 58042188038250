import React from 'react'
import myImg from "./BicharImage.jpg";
import { useNavigate } from 'react-router-dom';
import ScrollToTop from '../../../../../redux/ScrollToTop/ScrollToTop';
export default function BicharBlogBox() {
    const navigate=useNavigate()
  return (
    <div
          style={{
            // borderBottom: "2px solid #d1d1cf",
            padding: "10px 0px",
            margin: "0 10px",
          }}
          className="flex items-center h-[100px] cursor-pointer"
          onClick={()=>{
            navigate("/story")
            ScrollToTop()
          }}
        >
          <img
            src={myImg}
            alt="image"
            style={{ borderRadius: "5px" }}
            className="mx-3 w-[100px] h-full"
          />
          <span>
            <p
              className=" text-xl text-base my-1 line-clamp-3 text-[rgba(0,0,0,0.7)]"
              style={{ fontWeight: "600", lineHeight: "1.5" }}
            >
              गरिब किसान (कविता)
            </p>
            {/* <p
              className="text-xs "
              style={{ fontWeight: "600", lineHeight:'1.5', color: "#6b6b6a" }}
            >
              सोम, फागुन २८, २०८०
            </p> */}
          </span>
        </div>
  )
}
