import { Table } from "antd";
import React, { useState, useEffect } from "react";

export default function Review() {
  const [visitorCount, setVisitorCount] = useState(0);

  const dataSource = [
    {
      key: "1",
      name: "News 1",
      views: 3000,
      category: "Politics",
    },
    {
      key: "2",
      name: "News 2",
      views: 2500,
      category: "Technology",
    },
    {
      key: "3",
      name: "News 3",
      views: 2201,
      category: "Sports",
    },
    {
      key: "4",
      name: "News 4",
      views: 1632,
      category: "Business",
    },
    {
      key: "5",
      name: "News 5",
      views: 1122,
      category: "Accident",
    },
  ];

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      width:300,
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
  ];
  // Simulate fetching visitor count from backend
  useEffect(() => {
    const fetchVisitorCount = () => {
      // Mock data for now
      setVisitorCount(12345);
    };

    fetchVisitorCount();
  }, []);

  return (
    <div className="bg-green-100 w-full grid grid-cols-6 ">
      {/* Number of Visitors */}
      <div className="col-span-6 sm:col-span-2 h-auto ">
        <div
          className=" shadow-lg p-6 m-3 sm:m-6 bg-white rounded-lg"
          style={{ border: "6px solid #0c3d18" }}
        >
          <h2 className=" text-xl font-bold text-gray-700 mb-4">
            Number of Visitors
          </h2>
          <p className="text-3xl text-green-500 font-semibold">
            {visitorCount}
          </p>
        </div>
      </div>

      {/* Top 5 Most Viewed News */}
      <div className="col-span-6 sm:col-span-4 ">
        <div
          className="bg-white shadow-lg rounded-lg p-6 m-6 "
          style={{ border: "6px solid #0c3d18" }}
        >
          <h2 className="text-xl font-bold text-gray-700 mb-4">
            Top 5 Most Viewed News
          </h2>
          <Table dataSource={dataSource} columns={columns} />
        </div>
      </div>
    </div>
  );
}
