import React, { useEffect, useState } from "react";
import SmallCardContentRight from "../../common/cards/newscard/SmallCardContentRight";

export default function Card6() {
  const [phone, setPhone] = useState(false);
  useEffect(() => {
    const myWidth = window.innerWidth;
    if (myWidth >= 640) {
      setPhone(false);
    } else {
      setPhone(true);
    }
  }, []);
  return (
    <div className="grid grid-cols-2 my-5">
      <div
        className="col-span-2 sm:col-span-1 flex flex-col gap-8"
        style={{ padding: "15px" }}
      >
        <div className="h-[150px] sm:h-[120px]">
          <SmallCardContentRight textBlack="true" />
        </div>
        <div className="h-[150px] sm:h-[120px]">
          <SmallCardContentRight textBlack="true" />
        </div>
        <div className="h-[150px] sm:h-[120px]">
          <SmallCardContentRight textBlack="true" />
        </div>
      </div>
      {!phone && (
        <div
          className="col-span-2 sm:col-span-1 flex flex-col gap-8"
          style={{ padding: "15px" }}
        >
          <div className="h-[150px] sm:h-[120px]">
            <SmallCardContentRight textBlack="true" />
          </div>
          <div className="h-[150px] sm:h-[120px]">
            <SmallCardContentRight textBlack="true" />
          </div>
          <div className="h-[150px] sm:h-[120px]">
            <SmallCardContentRight textBlack="true" />
          </div>
        </div>
      )}
    </div>
  );
}
