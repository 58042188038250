import { UploadOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { Upload } from "antd";
import { Form } from "antd";
import React, { useState } from "react";

const FloatingLabelInput = ({ label, name, type = "text" }) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(!!value);

  return (
    <Form.Item
      className={`floating-label-input ${focused || value ? "focused" : ""}`}
      style={{ width: "300px" }}
    >
      <label htmlFor={name}>{label}</label>
      <Input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        width={500}
      />
    </Form.Item>
  );
};

export default function MemberAdd() {
  return (
    <div>
      <div className="my-5"></div>
      <FloatingLabelInput label="Name of member*" name="name" />
      <FloatingLabelInput label="Designation*" name="designation" />
      <FloatingLabelInput label="Display Order*" name="order" />
      <FloatingLabelInput label="Social Media Link" name="link" />
      <Form.Item>
        <Upload>
          <Button icon={<UploadOutlined />}>Choose File</Button>
        </Upload>
      </Form.Item>
    </div>
  );
}
