import React from "react";
import AuthorImg from "./pic.jpg";
import NewsImg from "./myImage.jpg";
import BicharBlog from "../../common/cards/newscard/sideBarComponents/BicharBlog";
import TajaSamachar from "../../common/cards/newscard/sideBarComponents/TajaSamachar";
import TrendingNews from "../../common/cards/newscard/sideBarComponents/TrendingNews";
export default function IndividualAuthor() {
  return (
    <div className="w-full flex justify-center my-5 sm:flex-wrap">
      <div
        style={{ width: "90%" }}
        className=" flex flex-col items-center py-4  gap-[20px]   "
      >
        <div
          className="flex flex-col sm:flex-row w-full gap-0  "
          style={{ borderBottom: "1px solid #898b8f" }}
        >
          <div
            style={{
              backgroundImage: `url(${AuthorImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderRadius: "100%",
            }}
            className="xl:mx-5  sm:my-5  w-[200px] sm:w-[150px] h-[320px] sm:h-[150px]"
          ></div>
          <div className="flex flex-col mt-[-20px] sm:mt-0 h-full justify-center gap-[5px]">
            <h2 className="font-mukta text-2xl font-bold ">चिरञ्जीवी पौडेल</h2>
            <h2 className="font-mukta text-xl  ">
              chirenjibepoudel123@gmail.com
            </h2>
            <h2 className="font-mukta text-l w-full">
              <a href="#" target="_blank">
                <ion-icon name="logo-facebook" size="large"></ion-icon>
              </a>
            </h2>
          </div>
        </div>
        <div className="w-full grid grid-cols-7">
          <div className="col-span-7 sm:col-span-4">
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <img
                src={NewsImg}
                alt="pic"
                className="w-[150px] sm:w-[250px] h-[150px] sm:mx-[10px]"
                style={{ borderRadius: "10px" }}
              />
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-2xl font-bold line-clamp-2 sm:w-[85%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
                {/* <h2 className="font-mukta text-l ">
                  This is the sub-heading of the News
                </h2> */}
              </span>
            </div>

            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <img
                src={NewsImg}
                alt="pic"
                className="w-[150px] sm:w-[250px] h-[150px] sm:mx-[10px]"
                style={{ borderRadius: "10px" }}
              />
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-2xl font-bold line-clamp-2 sm:w-[85%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
                {/* <h2 className="font-mukta text-l ">
                  This is the sub-heading of the News
                </h2> */}
              </span>
            </div>

            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <img
                src={NewsImg}
                alt="pic"
                className="w-[150px] sm:w-[250px] h-[150px] sm:mx-[10px]"
                style={{ borderRadius: "10px" }}
              />
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-2xl font-bold line-clamp-2 sm:w-[85%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
                {/* <h2 className="font-mukta text-l ">
                  This is the sub-heading of the News
                </h2> */}
              </span>
            </div>

            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />

            <div
              className="flex gap-[10px] my-3"
              style={{ backgroundColor: "#f7fff7", padding: "10px 15px" }}
            >
              <span className="flex flex-col gap-[8px]">
                <h2 className="font-mukta text-xl font-bold line-clamp-2 w-[80%]">
                  पुष्पजन्य उपजको समाचारका सम्बन्धमा प्लान्ट क्वारेन्टाइन तथा
                  विषादी व्यवस्थापन केन्द्रको ध्यानाकर्षण
                </h2>
              </span>
            </div>
            <hr className="h-2px " />
          </div>
          <div
            className="col-span-7 sm:col-span-3 xl:px-10 flex flex-col gap-[20px]"
            style={{
              minHeight: "80vh",
            }}
          >
            <TajaSamachar />
            <div className="sticky top-[120px]">
              <TrendingNews />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
