import React from "react";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import RateViewer from "./RateViewer.js";

const Navigation = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-full sm:w-[90%] ">
        <TopNav />
        {/* <div className="mySticky"> */}
        <BottomNav />
        <RateViewer />
        {/* </div> */}
      </div>
    </div>
  );
};

export default Navigation;
