import React, { useState } from "react";
import ContactTable from "./ContactTable";

export default function Contact() {
  return (
    <div className="flex justify-center items-center">
      <div style={{ width: "85%" }}>
        <ContactTable />
      </div>
    </div>
  );
}
