import { createContext, useState } from "react";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const LoginFunction = () => {
    // Your login logic here
    setIsLoggedIn(true);
  };

  return (
    <LoginContext.Provider value={{ isLoggedIn, LoginFunction }}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
