// App.js

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import makeStore from "./redux/store";
import PageUser from "./User/PageUser";
// import UserState from "./context/userState";
import { Toaster } from "sonner";
import { NavigationProvider } from "./Dashboard/NavigationContext";
import { LoginProvider } from "./context/LoginProvider";

function App() {
  return (
    <React.Fragment>
      <Provider store={makeStore()}>
        <BrowserRouter>
          <LoginProvider>
            <NavigationProvider>
              <PageUser />
              <Toaster richColors position="top-right" closeButton />
            </NavigationProvider>
          </LoginProvider>
        </BrowserRouter>
      </Provider>
    </React.Fragment>
  );
}

export default App;
