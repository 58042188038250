// import React from "react";

// export default function Table() {
//   return (
//     <div style={{ backgroundColor: "#7acf7e" }}>
//       <h1
//         className="font-bold"
//         style={{
//           textAlign: "center",
//           fontSize: "2.5rem",
//           margin: "0 0 20px 0",
//         }}
//       >
//         Kalimati Vegitable and Fruits Rate Today
//       </h1>
//       <hr
//         style={{
//           flex: 1,
//           border: "none",
//           borderTop: "2px solid black",
//         }}
//       />
//       <h1
//         className="font-bold"
//         style={{
//           textAlign: "center",
//           fontSize: "1.5rem",
//           margin: "0 0 20px 0",
//         }}
//       >
//         Daily prices - July 29, 2024 A.D.
//       </h1>
//       <table style={{ width: "100%" }}>
//         <thead style={{ backgroundColor: "#f0efa1", height: "40px" }}>
//           <th className="text-lg">
//             Commodity <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//           <th className="text-lg">
//             Unit <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//           <th className="text-lg">
//             Minimum <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//           <th className="text-lg">
//             Maximum <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//           <th className="text-lg">
//             Average <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//         </thead>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//       </table>
//     </div>
//   );
// }
import React from "react";

export default function Table() {
  return (
    <div className="flex justify-center w-full mt-10">
      <div className="w-full sm:w-[90%] h-[100vh] overflow-scroll ">
        <iframe
          src="https://nepalicalendar.rat32.com/vegetable/embed.php"
          frameBorder="0"
          scrolling="no"
          style={{
            border: "none",
            overflow: "hidden",
            width: "100%",
            height: "3000px", // Ensure height is sufficient for content
            borderRadius: "5px",
            padding: "0",
            margin: "0",
          }}
          allowTransparency="true"
        ></iframe>
      </div>
    </div>
  );
}
